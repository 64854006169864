import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Divider,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import MobileDateTimePicker from "@material-ui/lab/MobileDateTimePicker";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import FileUpload from "../inputs/file/FileUpload";
import { format } from "date-fns";


const localeMap = {
  ru: ruLocale,
  cs: csLocale,
};

const maskMap = {
  ru: "__.__.____",
  cs: "__.__.____",
};

const Modal7 = (props) => {
  const { t } = useTranslation();
  const [locale, setLocale] = useState(t("cs"));
  const [name, setName] = useState("");
  const { data: contextData, isLoading } = useContext(DataContext);

  var fd = {};
  JSON.parse(localStorage.getItem("columns")).map((column) => {
    if (!column.in_form) return false;
    if (column.model != props.model) return false;
    fd[column.field] = "";
    if (column.column_type == "date" || column.column_type == "datetime")
      fd[column.field] = null;
    if (column.column_type == "boolean") fd[column.field] = false;
    if (column.column_type == "multiselect") fd[column.field] = [];
  });

  const [finalform, setFinalform] = useState([]);
  const [form_data, setForm_data] = useState(fd);

  console.log({ form_data });

  const handleAdd = () => {
    props.onAdd(form_data);

    var fd = {};
    JSON.parse(localStorage.getItem("columns")).map((column) => {
      if (!column.in_form) return false;
      if (column.model != props.model) return false;
      fd[column.field] = "";
      if (column.column_type == "date" || column.column_type == "datetime")
        fd[column.field] = null;
      if (column.column_type == "boolean") fd[column.field] = false;
      if (column.column_type == "multiselect") fd[column.field] = [];
    });
    setForm_data(fd);
  };

  const handleChange = (field, column_type, event) => {
    if (props.model == "rests" && field == "typ") {
      const selectedRest = contextData.resty_types.find(
        (res) => res.id == event.target.value
      );
      const sumaValue = selectedRest ? selectedRest.cena : "";
      setForm_data((prevState) => ({
        ...prevState,
        suma: sumaValue,
      }));
    }
    if (props.model == "medical_checkups" && field == "type") {
      const selectedType = contextData.medical_types.find(
        (type) => type.id == event.target.value
      );
      const priceValue = selectedType ? selectedType.price : "";
      setForm_data((prevState) => ({
        ...prevState,
        price: priceValue,
      }));
    }
    if (
      column_type == "text" ||
      column_type == "decimal" ||
      column_type == "integer" ||
      column_type == "select"
    ) {
      setForm_data((prevState) => ({
        ...prevState,
        [field]: event.target.value,
      }));
    }
    if (column_type == "date") {
      setForm_data((prevState) => ({
        ...prevState,
        [field]: event.toISOString().split("T")[0],
      }));
    }
    if (column_type == "datetime") {
      setForm_data((prevState) => ({
        ...prevState,
        [field]: format(event, "yyyy-MM-dd HH:mm:ss"),
      }));
    }

    if (column_type == "boolean") {
      setForm_data((prevState) => ({
        ...prevState,
        [field]: !form_data[field],
      }));
    }

    if (column_type == "multiselect") {
      /*
             console.log(event);
             var fv='';
             event.forEach((e)=>{
             fv+=String(e.id)+'//';
             });
             */
      setForm_data((prevState) => ({
        ...prevState,
        [field]: event,
      }));
    }
  };

  const handleFileChange = (name, newValue, valid) => {
    setForm_data((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  useEffect(() => {
    prepareForm();
  }, [props.tmp, form_data]);

  const prepareForm = () => {
    var finform = [];

    JSON.parse(localStorage.getItem("columns")).map((column) => {
      if (!column.in_form) return false;
      if (column.model != props.model) return false;

      var required = false;
      if (column.required == 1) required = true;
      var general_column = column;
      if (
        general_column.column_type == "text" ||
        general_column.column_type == "integer" ||
        general_column.column_type == "decimal"
      ) {
        let label = t(column.name);
        finform.push(
          <Grid column_order={column.order} key={column.field} item xs={6}>
            <TextField
              disabled={!!column.disabled}
              fullWidth
              name={column.field}
              label={label}
              required={required}
              variant="outlined"
              value={form_data[column.field]}
              onChange={(event) => {
                handleChange(column.field, general_column.column_type, event);
              }}
            />
          </Grid>
        );
      }

      if (general_column.column_type == "date") {
        finform.push(
          <Grid column_order={column.order} key={column.field} item xs={6}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={localeMap[locale]}
            >
              <MobileDatePicker
                label={t(column.name)}
                name={column.field}
                required={required}
                value={form_data[column.field]}
                onChange={(event) => {
                  handleChange(column.field, general_column.column_type, event);
                }}
                renderInput={(inputProps) => (
                  <TextField fullWidth variant="outlined" {...inputProps} />
                )}
              />
            </LocalizationProvider>
          </Grid>
        );
      }
      if (general_column.column_type == "datetime") {
        finform.push(
          <Grid column_order={column.order} key={column.field} item xs={6}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={localeMap[locale]}
            >
              <MobileDateTimePicker
                label={t(column.name)}
                name={column.field}
                required={required}
                value={form_data[column.field]}
                onChange={(event) => {
                  handleChange(column.field, general_column.column_type, event);
                }}
                renderInput={(inputProps) => (
                  <TextField fullWidth variant="outlined" {...inputProps} />
                )}
              />
            </LocalizationProvider>
          </Grid>
        );
      }

      if (general_column.column_type == "select") {
        var values = [];
        let label = t(column.name);

        if (
          general_column.data_array == "custom" &&
          general_column.data != null &&
          general_column.data != ""
        )
          values = general_column.data;
        else if (
          general_column.data_array != "none" &&
          general_column.data_array != "" &&
          general_column.data_array != null
        )
          // values = JSON.parse(localStorage.getItem(general_column.data_array));
          values = contextData[general_column.data_array];

        if (
          general_column.field == "typ" &&
          general_column.model == "fines" &&
          props.typ == "fines.koordinator"
        ) {
          var vals = [];
          values.map((e) => {
            if (e.koordinator) vals.push(e);
          });
          values = vals;
        }

        if (
          general_column.field == "typ" &&
          general_column.model == "fines" &&
          props.typ == "fines.ubytovatel"
        ) {
          var vals = [];
          values.map((e) => {
            if (!e.koordinator) vals.push(e);
          });
          values = vals;
        }

        if (general_column.field == "typ" && general_column.model == "rests") {
          var vals = [];

          const customer = form_data.uzivatel;

          customer &&
            values.map((e) => {
              if (e.customer_id == form_data.uzivatel) vals.push(e);
            });

          values = vals;
        }

        if (
          general_column.field == "misto_vykonu" &&
          general_column.model == "assignments"
        ) {
          var vals = [];

          const customer = form_data.uzivatel;

          customer &&
            values.map((e) => {
              if (e.customers.includes(+form_data.uzivatel)) vals.push(e);
            });

          values = vals;
        }
        // if (
        //   general_column.field == "doctor" &&
        //   general_column.model == "medical_checkups"
        // ) {
        //   var vals = [];
        //   console.log({ form_data });
        //   const types = contextData.medical_types;
        //   const type = types.find((t) => t.id == form_data.type);

        //   type &&
        //     values.map((e) => {
        //       if (e.id == type.doctor) vals.push(e);
        //     });

        //   values = vals;
        // }
        if (
          general_column.field == "doctor" &&
          general_column.model == "medical_checkups"
        ) {
          values = values.filter((item) => !item.deleted);
        }

        if (general_column.field === "uzivatel") {
          values = values.filter((i) => i.is_visible);
        }
        if (
          general_column.field === "uzivatel" &&
          general_column.model == "rests"
        ) {
          let allowed_options = JSON.parse(
            localStorage.getItem("policy_record_tree")
          ).customers;
          values = values.filter((i) =>
            allowed_options.includes(i.id.toString())
          );
        }

        finform.push(
          <Grid column_order={column.order} key={column.field} item xs={6}>
            <TextField
              fullWidth
              name={column.field}
              label={label}
              required={required}
              select
              SelectProps={{ native: true }}
              variant="outlined"
              value={form_data[column.field]}
              onChange={(event) => {
                handleChange(column.field, general_column.column_type, event);
              }}
            >
              <option key="0" value=""></option>
              {values.map((c) => (
                <option key={c.id} value={c.id}>
                  {t(c.name)}
                </option>
              ))}
            </TextField>
          </Grid>
        );
      }

      if (general_column.column_type == "multiselect") {
        var values = [];
        if (general_column.data_array == "custom") values = general_column.data;
        else if (
          general_column.data_array != "none" &&
          general_column.data_array != "" &&
          general_column.data_array != null
        )
          values = JSON.parse(localStorage.getItem(general_column.data_array));
        finform.push(
          <Grid column_order={column.order} key={column.field} item xs={6}>
            <Autocomplete
              multiple
              id="tags-standard"
              options={values}
              value={form_data[column.field]}
              getOptionLabel={(option) => t(option.name)}
              onChange={(event, value) => {
                handleChange(column.field, general_column.column_type, value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={t(column.name)}
                  placeholder={t(column.name)}
                />
              )}
            />
          </Grid>
        );
      }

      if (general_column.column_type == "boolean") {
        finform.push(
          <Grid column_order={column.order} key={column.field} item xs={6}>
            <FormControlLabel
              sx={{ marginLeft: 1 }}
              control={
                <Switch
                  color="primary"
                  edge="start"
                  checked={form_data[column.field]}
                  onChange={(event) => {
                    handleChange(
                      column.field,
                      general_column.column_type,
                      event
                    );
                  }}
                />
              }
              label={t(column.name)}
            />
          </Grid>
        );
      }

      if (
        general_column.column_type === "file" ||
        general_column.column_type === "multifile"
      ) {
        finform.push(
          <Grid column_order={column.order} item xs={6} key={column.field}>
            <FileUpload
              multiple={general_column.column_type === "multifile"}
              name={column.field}
              label={t(column.name)}
              onChange={handleFileChange}
              file={form_data[column.field]}
              required={required}
            />
          </Grid>
        );
      }
    });

    finform.sort((a, b) => a.props.order - b.props.order);
    setFinalform(finform);
  };

  return (
    <Paper
      elevation={12}
      sx={{
        maxWidth: 620,
        mx: "auto",
        p: 2,
      }}
    >
      <ValidatorForm
        onSubmit={handleAdd}
        onError={(errors) => console.log(errors)}
      >
        <Typography color="textPrimary" variant="h6">
          {t("Přidat")}
        </Typography>
        <Divider sx={{ marginBottom: 3 }} />
        <Grid container spacing={3}>
          {finalform}

          <Grid item xs={12}>
            <Button color="primary" fullWidth variant="contained" type="submit">
              {t("Uložit")}
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Paper>
  );
};

export default Modal7;
