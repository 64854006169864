import PropTypes from "prop-types";
import toast from "react-hot-toast";
import debounce from "lodash/debounce";
import {
  Box,
  Dialog,
  Divider,
  Grid,
  TextField,
  Typography,
  Modal,
  Autocomplete,
  Button,
  DialogTitle,
  DialogActions,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import ArchiveIcon from "../../../icons/Archive";
import DocumentTextIcon from "../../../icons/DocumentText";
import { MobileDatePicker } from "@material-ui/lab";
import { compareDesc } from "date-fns";
import Chip from "@mui/material/Chip";
import { useTranslation } from "react-i18next";
import {
  deleteCard,
  updateCard,
  updateAttachmentsList,
  deleteAttachment,
  updateEmployeeList,
} from "../../../slices/vacancies";
import { useDispatch, useSelector } from "../../../store";
import KanbanCardAction from "./KanbanCardAction";
import PrioritySelector from "./PrioritySelector";
import KanbanChecklist from "./KanbanChecklist";
import KanbanComment from "./KanbanComment";
import KanbanCommentAdd from "./KanbanCommentAdd";
import EmployeesTable from "./EmployeesTable";
import { useEffect, useState } from "react";
import { useContext, useRef } from "react";
import { DataContext } from "../../../contexts/DataContext";
import HttpService from "../../../services/HttpService.js";
import UrlService from "../../../services/UrlService.js";
import Repeats from "./Repeats.js";

const KanbanCardModal = (props) => {
  const { t } = useTranslation();
  const { setCard = () => {}, card, column, onClose, open, ...other } = props;
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [requestType, setRequestType] = useState("");
  const { data: contextData } = useContext(DataContext);
  const { professions, languages, countries } = contextData;
  const photoInputRef = useRef(null);
  const { cards } = useSelector((state) => state.vacancies);
  const state = useSelector((state) => state.vacancies);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [people, setPeople] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const sexList = [
    { id: "1", name: "M" },
    { id: "2", name: "Ž" },
  ];

  const driverLicenceGroups = [
    { id: "1", name: "A" },
    { id: "2", name: "B" },
    { id: "3", name: "C" },
    { id: "5", name: "D" },
    { id: "6", name: "BE" },
    { id: "7", name: "CE" },
    { id: "8", name: "DE" },
    { id: "9", name: "Žádná" },
    { id: "10", name: "T" },
  ];

  const salaryTypes = [
    {
      id: "1",
      name: "Hrubého na hodinu",
    },
    {
      id: "2",
      name: "Čistého na hodinu",
    },
    {
      id: "3",
      name: "Hrubého měsíčně",
    },
    {
      id: "4",
      name: "Čistého měsíčně",
    },
  ];

  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).vacancies;

  const allowedCustomers = JSON.parse(
    localStorage.getItem("policy_record_tree")
  ).customers;

  const isDodavatel = policy_actions.includes("dodavatel_view");

  const disabled =
    !allowedCustomers.includes(column.id.toString()) &&
    !policy_actions.includes("edit");
  // const canEditDates = policy_actions.includes("edit_dates");

  const hasUnapprovedPeople = card.checklists[0].checkItems.some(
    (item) => item.approved == 0
  );

  const canSeeEmployees = allowedCustomers.includes(column.id.toString());

  const at = localStorage.getItem("accessToken");

  const handleSearch = (value) => {
    let url = isDodavatel
      ? `api/getAvailableUsersBySupplier?search=${value}`
      : `api/getAvailableUsers?search=${value}`;
    if (value.length > 2 && value.length < 5) {
      HttpService.get(UrlService.apiDomain() + url)
        .then((res) => {
          setPeople([...people, ...res.data]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleEmployeeAdd = async (id) => {
    if (id) {
      try {
        const response = await HttpService.post(
          UrlService.apiDomain() + "api/add-employee-to-vacancy",
          {
            employee_id: id,
            vacancy_id: card.id,
            state: true,
          }
        );
        if (response.status != 200) {
          toast.error(t("Něco se pokazilo!"));
          return;
        }
        dispatch(
          updateEmployeeList({
            list: response.data.checklists[0].checkItems,
            cardId: card.id,
          })
        );
        setSelectedEmployee(null);
        setPeople([]);
        toast.success(t("Záznam obnoven!"));
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleVacanciesOpen = () => {
    setOpenModal(true);
    setRequestType("profession");
  };
  const handleCustomerOpen = () => {
    setOpenModal(true);
    setRequestType("customer");
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleDetailsUpdate = debounce(async (update) => {
    try {
      await dispatch(updateCard({ cardId: card.id, update }));
    } catch (err) {
      console.error(err);
      toast.error(t("Něco se pokazilo!"));
    }
  }, 0);

  useEffect(() => {
    setCard(cards.byId[card.id]);
  }, [state]);

  const handleDelete = async () => {
    const addedEmployees = card.checklists[0].checkItems;
    const approvedEmployees = addedEmployees.filter(
      (emp) => emp.state === "complete"
    ).length;

    try {
      await dispatch(
        deleteCard({
          id: card.id,
          employeeAdded: addedEmployees.length,
          employeeApproved: approvedEmployees,
        })
      );
      toast.success(t("Úloha odstraněna!"));
    } catch (err) {
      console.error(err);
      toast.error(t("Něco se pokazilo!"));
    }
  };

  const downloadAttachment = (documentUrl) => {
    const link = document.createElement("a");
    link.href = documentUrl;
    link.setAttribute("download", document.name);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onUpload = async (e) => {
    try {
      const formData = new FormData();
      const files = e.currentTarget.files;

      for (let i = 0; i < files.length; i++) {
        formData.append(`file${[i]}`, files[i]);
      }
      const response = await HttpService.post(
        UrlService.apiDomain() + `api/add-vacancy-attachment/${card.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + at,
          },
        }
      );
      dispatch(updateAttachmentsList({ list: response.data, cardId: card.id }));
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAtt = async (id) => {
    try {
      const response = await HttpService.delete(
        UrlService.apiDomain() + `api/delete-vacancy-attachment/${id}`
      );
      if (response.status !== 200) {
        toast.error(t("Něco se pokazilo!"));
        return;
      }
      dispatch(deleteAttachment({ attachmentId: id, cardId: card.id }));
      toast.success(t("Soubor smazán"));
    } catch (error) {
      console.log(error);
    }
  };

  const sortedComments = [...card.comments].sort((comment1, comment2) => {
    const createdAt1 = new Date(comment1.createdAt);
    const createdAt2 = new Date(comment2.createdAt);
    return compareDesc(createdAt1, createdAt2);
  });

  return (
    <>
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>{t("Opravdu chcete záznam archivovat?")}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="primary">
            {t("Zrušit")}
          </Button>
          <Button onClick={handleDelete} color="primary">
            {t("Potvrdit")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="md" onClose={onClose} open={open} {...other}>
        <Modal onClose={handleClose} open={openModal}>
          <div>
            <EmployeesTable
              type={requestType}
              vacancyId={card.id}
              handleOptionsClose={handleClose}
            />
          </div>
        </Modal>
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ mb: 3 }}>
            {column.name}
          </Typography>
          <Grid container spacing={5}>
            <Grid item sm={8} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    disabled={disabled}
                    defaultValue={card.name}
                    fullWidth
                    label={t("Název")}
                    onChange={(event) =>
                      handleDetailsUpdate({ name: event.target.value })
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    disabled={disabled}
                    id={`profession`}
                    name={`profession`}
                    fullWidth
                    defaultValue={card.profession}
                    options={professions}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => {
                      handleDetailsUpdate({ profession: value?.id || "" });
                    }}
                    value={
                      professions.find(
                        (option) => option.id == card.profession
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("Pracovní zkušenosti")} />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    disabled={disabled}
                    defaultValue={card.employeeNeeded}
                    fullWidth
                    label={t("Počet lidí")}
                    onChange={(event) =>
                      handleDetailsUpdate({
                        employeeNeeded: event.target.value,
                      })
                    }
                    variant="outlined"
                    inputProps={{
                      onKeyPress: (event) => {
                        const charCode = event.which
                          ? event.which
                          : event.keyCode;
                        if (
                          (charCode !== 8 && charCode !== 0 && charCode < 48) ||
                          charCode > 57
                        ) {
                          event.preventDefault();
                        }
                      },
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    name={"citizenship"}
                    limitTags={2}
                    disabled={disabled}
                    options={countries}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    multiple
                    onChange={(e, value) => {
                      handleDetailsUpdate({
                        citizenship: value.map((val) => val.id.toString()),
                      });
                    }}
                    value={
                      countries.filter((item) =>
                        card?.citizenship?.includes(item.id.toString())
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("Statni občanství")} />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    name={"sex"}
                    disabled={disabled}
                    options={sexList}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    multiple
                    onChange={(e, value) => {
                      handleDetailsUpdate({
                        sex: value.map((val) => val.id.toString()),
                      });
                    }}
                    value={
                      sexList.filter((item) =>
                        card?.sex?.includes(item.id.toString())
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("Pohlaví")} />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    name={"languages"}
                    limitTags={2}
                    disabled={disabled}
                    options={languages}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    multiple
                    onChange={(e, value) => {
                      handleDetailsUpdate({
                        languages: value.map((val) => val.id.toString()),
                      });
                    }}
                    value={
                      languages.filter((item) =>
                        card?.languages?.includes(item.id.toString())
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("Znalost jazyku")} />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Autocomplete
                    name={"driver_license_group"}
                    limitTags={2}
                    disabled={disabled}
                    options={driverLicenceGroups}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    multiple
                    onChange={(e, value) => {
                      handleDetailsUpdate({
                        driver_license_group: value.map((val) =>
                          val.id.toString()
                        ),
                      });
                    }}
                    value={
                      driverLicenceGroups.filter((item) =>
                        card?.driver_license_group?.includes(item.id.toString())
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("Řidičský průkaz")} />
                    )}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    name="age"
                    disabled={disabled}
                    defaultValue={card.age}
                    label={t("Maximální věk")}
                    variant="outlined"
                    fullWidth
                    onChange={(event) =>
                      handleDetailsUpdate({ age: event.target.value })
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                    <FormControlLabel
                      label={t("VZV")}
                      control={
                        <Checkbox
                          disabled={disabled}
                          id="forklift_license"
                          name="forklift_license"
                          type="checkbox"
                          onChange={(event) =>
                            handleDetailsUpdate({
                              forklift_license: event.target.checked,
                            })
                          }
                          checked={card?.forklift_license}
                        />
                      }
                    />
                    <FormControlLabel
                      label={t("Svářečský průkaz")}
                      control={
                        <Checkbox
                          disabled={disabled}
                          id="welding_license"
                          name="welding_license"
                          type="checkbox"
                          onChange={(event) =>
                            handleDetailsUpdate({
                              welding_license: event.target.checked,
                            })
                          }
                          checked={card?.welding_license}
                        />
                      }
                    />
                  </Box>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Autocomplete
                    name={"salary_type"}
                    disabled={disabled}
                    options={salaryTypes}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    onChange={(e, value) => {
                      handleDetailsUpdate({
                        salary_type: value.id.toString(),
                      });
                    }}
                    value={
                      salaryTypes.find(
                        (item) => card?.salary_type == item.id.toString()
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("Typ mzdy")} />
                    )}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    name="salary"
                    disabled={disabled}
                    defaultValue={card.salary}
                    label={t("Mzda od")}
                    variant="outlined"
                    fullWidth
                    onChange={(event) =>
                      handleDetailsUpdate({ salary: event.target.value })
                    }
                    inputProps={{
                      onKeyPress: (event) => {
                        const charCode = event.which
                          ? event.which
                          : event.keyCode;
                        if (
                          (charCode !== 8 && charCode !== 0 && charCode < 48) ||
                          charCode > 57
                        ) {
                          event.preventDefault();
                        }
                      },
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    name="salary_to"
                    disabled={disabled}
                    defaultValue={card.salary_to}
                    label={t("Mzda do")}
                    variant="outlined"
                    fullWidth
                    onChange={(event) =>
                      handleDetailsUpdate({ salary_to: event.target.value })
                    }
                    inputProps={{
                      onKeyPress: (event) => {
                        const charCode = event.which
                          ? event.which
                          : event.keyCode;
                        if (
                          (charCode !== 8 && charCode !== 0 && charCode < 48) ||
                          charCode > 57
                        ) {
                          event.preventDefault();
                        }
                      },
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    name="bonus"
                    disabled={disabled}
                    defaultValue={card.bonus}
                    label={t("Bonusy")}
                    variant="outlined"
                    fullWidth
                    onChange={(event) =>
                      handleDetailsUpdate({ bonus: event.target.value })
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    name="shift"
                    disabled={disabled}
                    defaultValue={card.shift}
                    label={t("Směny")}
                    variant="outlined"
                    fullWidth
                    onChange={(event) =>
                      handleDetailsUpdate({ shift: event.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={disabled}
                    defaultValue={card.description}
                    fullWidth
                    multiline
                    onChange={(event) =>
                      handleDetailsUpdate({ description: event.target.value })
                    }
                    placeholder={t("Napište popis")}
                    label={t("Popis")}
                    rows={6}
                    variant="outlined"
                  />
                </Grid>
                {policy_actions.includes("internal_note") && (
                  <Grid item xs={12}>
                    <TextField
                      disabled={disabled}
                      defaultValue={card.internal_note}
                      fullWidth
                      multiline
                      onChange={(event) =>
                        handleDetailsUpdate({
                          internal_note: event.target.value,
                        })
                      }
                      placeholder={t("Napište poznámku")}
                      label={t("Interní poznámka")}
                      rows={3}
                      variant="outlined"
                    />
                  </Grid>
                )}
              </Grid>
              {card.attachments.length > 0 && (
                <Box sx={{ mt: 5, display: "flex", gap: 2 }}>
                  {card.attachments.map((attachment) => (
                    <Chip
                      key={attachment.id}
                      color="primary"
                      sx={{ width: "25%" }}
                      label={attachment.name}
                      variant="outlined"
                      onClick={() => downloadAttachment(attachment.path)}
                      onDelete={() => deleteAtt(attachment.id)}
                    />
                  ))}
                </Box>
              )}
              {card.checklists.length > 0 && canSeeEmployees && (
                <Box sx={{ mt: 5 }}>
                  {card.checklists.map((checklist) => (
                    <KanbanChecklist
                      card={card}
                      checklist={checklist}
                      key={checklist.id}
                      sx={{ mb: 3 }}
                    />
                  ))}
                </Box>
              )}
              {!isDodavatel && (
                <Box sx={{ mt: 3 }}>
                  <Typography color="textPrimary" variant="h6">
                    {t("Aktivita")}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <KanbanCommentAdd cardId={card.id} />
                    {card.comments.length > 0 && (
                      <Box sx={{ mt: 3 }}>
                        {sortedComments.map((comment) => (
                          <KanbanComment
                            createdAt={comment.createdAt}
                            key={comment.id}
                            commentId={comment.id}
                            memberId={comment.memberId}
                            message={comment.message}
                            cardId={card.id}
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                color="textPrimary"
                component="h4"
                sx={{
                  fontWeight: 600,
                  mb: 2,
                }}
                variant="overline"
              >
                {t("Přidat do práce")}
              </Typography>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <Autocomplete
                  disabled={!policy_actions.includes("add_man")}
                  name={`employee`}
                  options={people}
                  getOptionLabel={(option) =>
                    `${option.name} ${option.surname}, ${option?.date_of_birth}, ${option?.code}`
                  }
                  onChange={(e, value) => {
                    setSelectedEmployee(value);
                    handleEmployeeAdd(value?.id);
                  }}
                  value={selectedEmployee}
                  onInputChange={(e, value) => handleSearch(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Zadejte první tři písmena příjmení")}
                    />
                  )}
                />
              </FormControl>
              {/* <KanbanCardAction
                icon={<CheckIcon fontSize="small" />}
                onClick={handleAddChecklist}
              >
                Checklist
              </KanbanCardAction>
              <KanbanCardAction disabled icon={<UsersIcon fontSize="small" />}>
                Members
              </KanbanCardAction> */}
              <KanbanCardAction
                disabled={
                  !(policy_actions.includes("add_man") && card.profession)
                  // disabled
                }
                onClick={handleVacanciesOpen}
              >
                {t("Vybrat podle")}{" "}
                {card.profession
                  ? professions.find((opt) => opt.id == card.profession).name
                  : t("Pracovní zkušenosti")}
                {/* Vybrat podle Pracovni zkušenosti */}
              </KanbanCardAction>
              <KanbanCardAction
                disabled={!policy_actions.includes("add_man")}
                onClick={handleCustomerOpen}
              >
                {t("Vybrat podle pref. uživatele/lokality")}
              </KanbanCardAction>

              <Box sx={{ mt: 3 }}>
                <Typography
                  color="textPrimary"
                  component="h4"
                  sx={{
                    fontWeight: 600,
                    mb: 2,
                  }}
                  variant="overline"
                >
                  {t("Akce")}
                </Typography>
                {/* <KanbanCardAction
                  disabled
                  icon={<ArrowRightIcon fontSize="small" />}
                >
                  Move
                </KanbanCardAction>
                <KanbanCardAction
                  disabled
                  icon={<DuplicateIcon fontSize="small" />}
                >
                  Copy
                </KanbanCardAction> */}
                {/* <KanbanCardAction
                  disabled
                  icon={<TemplateIcon fontSize="small" />}
                >
                  Make Template
                </KanbanCardAction> */}
                <Divider sx={{ my: 2 }} />
                {/* {card.isSubscribed ? (
                  <KanbanCardAction
                    icon={<EyeOffIcon fontSize="small" />}
                    onClick={handleUnsubscribe}
                  >
                    Unwatch
                  </KanbanCardAction>
                ) : (
                  <KanbanCardAction
                    icon={<EyeIcon fontSize="small" />}
                    onClick={handleSubscribe}
                  >
                    Watch
                  </KanbanCardAction>
                )} */}
                <Box sx={{ mb: 2 }}>
                  <Repeats data={JSON.parse(card.datum_nastupu_history)} />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <MobileDatePicker
                    disabled={disabled}
                    sx={{ mb: 2 }}
                    label={t("Datum nástupu")}
                    inputFormat="dd.MM.yyyy"
                    value={card.due}
                    onChange={() => {}}
                    onAccept={(value) => {
                      handleDetailsUpdate({ due: value });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" fullWidth />
                    )}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <MobileDatePicker
                    disabled={disabled}
                    label={t("Pohovor")}
                    inputFormat="dd.MM.yyyy"
                    value={card.interveiwDay}
                    onChange={() => {}}
                    onAccept={(value) => {
                      handleDetailsUpdate({ interveiwDay: value });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" fullWidth />
                    )}
                  />
                </Box>
                {/* <KanbanCardAction disabled icon={<LabelIcon fontSize="small" />}>
                  Priority
                </KanbanCardAction> */}
                <Box
                  sx={{
                    mb: 2,
                    display: "inline-flex",
                    gap: 2,
                    width: 1,
                    px: 2,
                  }}
                >
                  <PrioritySelector
                    card={card}
                    handleDetailsUpdate={handleDetailsUpdate}
                  />
                  <Typography
                    color="textPrimary"
                    component="h6"
                    sx={{
                      fontWeight: 600,
                    }}
                    variant="overline"
                  >
                    {t("Priority")}
                  </Typography>
                </Box>
                <input
                  id="photos"
                  name="photos"
                  onChange={onUpload}
                  multiple
                  hidden
                  ref={photoInputRef}
                  type="file"
                />

                <KanbanCardAction
                  disabled={disabled}
                  icon={<DocumentTextIcon fontSize="small" />}
                  onClick={() => photoInputRef.current.click()}
                >
                  {t("Příloha")}
                </KanbanCardAction>
                {policy_actions.includes("archiv") && (
                  <KanbanCardAction
                    disabled={hasUnapprovedPeople}
                    icon={<ArchiveIcon fontSize="small" />}
                    onClick={() => setConfirmOpen(true)}
                  >
                    {t("Archiv")}
                  </KanbanCardAction>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

KanbanCardModal.propTypes = {
  card: PropTypes.object.isRequired,
  column: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

KanbanCardModal.defaultProps = {
  open: false,
};

export default KanbanCardModal;
