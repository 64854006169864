import {
  ViewPageEmploymentDepartmentInfos as ViewPageEmploymentDepartmentInfos,
  ViewPageEmploymentDepartmentNotices as ViewPageEmploymentDepartmentNotices,
} from "./Main.mjs";

import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./components/AuthGuard";
import BlogLayout from "./components/blog/BlogLayout";
import BrowseLayout from "./components/BrowseLayout";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import DocsLayout from "./components/docs/DocsLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./components/MainLayout";

import "ag-grid-community/dist/styles/ag-grid.css";

import "ag-grid-community/dist/styles/ag-theme-material.css";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Browse pages
const WashingMachines = Loadable(
  lazy(() => import("./pages/washing_machines/washingMachineReservations"))
);
const CompanyEmployees = Loadable(
  lazy(() => import("./pages/employees/companyEmployees"))
);
const Browse = Loadable(lazy(() => import("./pages/browse/Browse")));
const Users = Loadable(lazy(() => import("./pages/settings/users")));
const Suppliers = Loadable(lazy(() => import("./pages/settings/users")));
const SupplierInvoices = Loadable(lazy(() => import("./pages/suppliers")));
const Customers = Loadable(lazy(() => import("./pages/settings/customers")));
const CustomersFirms = Loadable(lazy(() => import("./pages/customers")));
const CustomerStates = Loadable(
  lazy(() => import("./pages/customers/CustomerStates.js"))
);
const CustomerRequirementConditions = Loadable(
  lazy(() => import("./pages/customers/CustomerRequirementConditions.js"))
);
const AllCustomerRequirements = Loadable(
  lazy(() => import("./pages/all_customers_requirements"))
);
const VacancyInfo = Loadable(
  lazy(() => import("./components/dashboard/vacancies/VacancyInfo.js"))
);
const WorkPlaces = Loadable(lazy(() => import("./pages/settings/work_places")));
const Notifications = Loadable(
  lazy(() => import("./pages/settings/notifications"))
);

const Agencies = Loadable(lazy(() => import("./pages/settings/agencies")));
const InvoicePayers = Loadable(
  lazy(() => import("./pages/settings/invoice_payers"))
);
const InvoiceSuppliers = Loadable(
  lazy(() => import("./pages/settings/invoice_suppliers"))
);
const PokladniTypes = Loadable(
  lazy(() => import("./pages/settings/pokladni_types"))
);
const DochazkaStatuses = Loadable(
  lazy(() => import("./pages/settings/dochazka_statuses"))
);
const FineTypes = Loadable(lazy(() => import("./pages/settings/fine_types")));

const Accommodations = Loadable(
  lazy(() => import("./pages/settings/accommodations"))
);

const PlaceAdresses = Loadable(
  lazy(() => import("./pages/settings/place_addresses"))
);
const AccommodationStatistic = Loadable(
  lazy(() =>
    import(
      "./pages/reports/accommodation_support_tables/AccommodationStatistic.js"
    )
  )
);

const OtherSettings = Loadable(
  lazy(() => import("./pages/settings/other_settings"))
);
const Departments = Loadable(lazy(() => import("./pages/departments")));
const CustomerDepartments = Loadable(
  lazy(() => import("./pages/customer_departments"))
);
const EmployeeCustomers = Loadable(
  lazy(() => import("./pages/employee_customers"))
);
const Employees = Loadable(lazy(() => import("./pages/employees")));
const MailTemplates = Loadable(
  lazy(() => import("./pages/settings/mail_templates"))
);
const PaymentsBk = Loadable(
  lazy(() => import("./pages/settings/payments/payments"))
);
const Companies = Loadable(
  lazy(() => import("./pages/settings/companies/companies"))
);
const Documents = Loadable(
  lazy(() => import("./pages/settings/documents/documents"))
);
const DocumentTags = Loadable(
  lazy(() => import("./pages/settings/documents/tags/documentTags"))
);
const ReportDochazky = Loadable(
  lazy(() => import("./pages/reports/report_dochazky.js"))
);
const ReportAccommodation = Loadable(
  lazy(() => import("./pages/reports/ReportAccommodation.js"))
);
const ReportReception = Loadable(
  lazy(() => import("./pages/reports/ReportReception.js"))
);
const ReportRecruitment = Loadable(
  lazy(() => import("./pages/reports/reportRecruitment"))
);
const ReportReservation = Loadable(
  lazy(() => import("./pages/reports/ReportReservation.js"))
);
const ReportInventura = Loadable(
  lazy(() => import("./pages/reports/reportInventura"))
);
const ReportRewards = Loadable(
  lazy(() => import("./pages/reports/reportRewards"))
);
const ReportRewardNew = Loadable(
  lazy(() => import("./pages/reports/reportRewardsNew"))
);
const ReportBonus = Loadable(lazy(() => import("./pages/reports/reportBonus")));
const ReportProvision = Loadable(
  lazy(() => import("./pages/reports/reportProvisions"))
);

const ReportCashier = Loadable(
  lazy(() => import("./pages/reports/reportCashier"))
);
const ReportCollection = Loadable(
  lazy(() => import("./pages/reports/reportCollection/ReportCollection.js"))
);
const ReportCommission = Loadable(
  lazy(() => import("./pages/reports/reportCommission"))
);
const SupplierAccountNumbers = Loadable(
  lazy(() => import("./pages/supplier_account_numbers"))
);
const ReportRequests = Loadable(
  lazy(() => import("./pages/reports/reportRequests/ReportRequests.js"))
);
const ReportSalaries = Loadable(
  lazy(() => import("./pages/reports/reportSalaries"))
);
const MinusSalaries = Loadable(
  lazy(() => import("./pages/reports/reportSalaries/MinusSalaries"))
);
const PersonalReport = Loadable(
  lazy(() => import("./pages/reports/personalReport"))
);
const ReportMezd = Loadable(lazy(() => import("./pages/reports/reportMezd")));
const ReportHours = Loadable(lazy(() => import("./pages/reports/reportHours")));
const ReservForm = Loadable(
  lazy(() => import("./components/placeReservationForm/ReservForm.js"))
);
const InventoryItems = Loadable(
  lazy(() => import("./pages/settings/inventoryItems/InventoryItems.js"))
);
const RequestsTypes = Loadable(
  lazy(() => import("./pages/settings/requests/RequestsTypes.js"))
);
const ReportMoving = Loadable(
  lazy(() => import("./pages/reports/reportMoving/ReportMoving.js"))
);
const Translations = Loadable(
  lazy(() => import("./pages/settings/translations"))
);
const SupplierReport = Loadable(
  lazy(() => import("./pages/reports/Supplier_report.js"))
);
const AccountNumbers = Loadable(lazy(() => import("./pages/account_number")));
const Projects = Loadable(lazy(() => import("./pages/projects")));
const SubProjects = Loadable(lazy(() => import("./pages/sub_projects")));
const Rewards = Loadable(lazy(() => import("./pages/settings/rewards")));
const Forms = Loadable(lazy(() => import("./pages/settings/forms")));
const ReceivedInvoices = Loadable(
  lazy(() => import("./pages/received_invoices"))
);

const CustomerLegal = Loadable(lazy(() => import("./pages/customer_legals")));
const PendingInvoices = Loadable(
  lazy(() => import("./pages/received_invoices/pending_invoices"))
);
const ForApprovalInvoices = Loadable(
  lazy(() => import("./pages/received_invoices/for_approval_invoices"))
);
const WireTransfers = Loadable(lazy(() => import("./pages/wire_transfer")));
const AdditionalTexts = Loadable(
  lazy(() => import("./pages/additional_texts"))
);
const InvoiceItems = Loadable(lazy(() => import("./pages/invoice_items")));
const InvoiceItemNames = Loadable(
  lazy(() => import("./pages/invoice_items/invoice_item_names"))
);
const IssuedInvoices = Loadable(lazy(() => import("./pages/issued_invoices")));
const IssuedInvoiceReport = Loadable(
  lazy(() => import("./pages/issued_invoices/issuedInvoicesReport"))
);
const Clients = Loadable(lazy(() => import("./pages/clients")));
const Cars = Loadable(lazy(() => import("./pages/cars")));
const CheckupsTypes = Loadable(
  lazy(() => import("./pages/settings/medicalCheckupTypes"))
);
const MedicalCheckups = Loadable(lazy(() => import("./pages/medicalCheckups")));
const Downpayments = Loadable(lazy(() => import("./pages/downpayments")));
const DownpaymentsRequests = Loadable(
  lazy(() => import("./pages/downpayments/downpaymentsRequests"))
);
const Payments = Loadable(lazy(() => import("./pages/payments")));
const RegularPayments = Loadable(
  lazy(() => import("./pages/regular_payments"))
);
const ReportPeople = Loadable(
  lazy(() => import("./pages/reports/reportPeople"))
);

const Permissions = Loadable(lazy(() => import("./pages/permissions")));
const PermissionsTemplates = Loadable(
  lazy(() => import("./pages/permissions_templates"))
);

const MassPermissions = Loadable(
  lazy(() => import("./pages/permissions_mass"))
);

const ExpenseTypes = Loadable(
  lazy(() => import("./pages/settings/expense_types"))
);
const CoordinatorExpencesTypes = Loadable(
  lazy(() => import("./pages/settings/coordinator_expences_types/index.js"))
);
const CoordinatorExpencesReport = Loadable(
  lazy(() => import("./pages/reports/reportCoordinatorExpenses"))
);
const CoordinatorExpences = Loadable(
  lazy(() => import("./pages/coordinator_expences"))
);

const CoordinatorMoneyRequests = Loadable(
  lazy(() => import("./pages/coordinator_money_requests"))
);

const AccountingRequests = Loadable(
  lazy(() => import("./pages/accounting_requests"))
);
const Feedbacks = Loadable(lazy(() => import("./pages/reports/feedbacks")));

const CoordinatorReports = Loadable(
  lazy(() => import("./pages/coordinator_reports"))
);

const PhoneCalls = Loadable(lazy(() => import("./pages/phone_calls")));
const Newsletters = Loadable(
  lazy(() => import("./pages/settings/newsletters"))
);
const UnsubscribeConfirm = Loadable(
  lazy(() => import("./pages/settings/newsletters/UnsubscribeConfirm.js"))
);
const ReportSuppliers = Loadable(
  lazy(() => import("./pages/reports/reportSuppliers"))
);

const RewardRuleBlocks = Loadable(
  lazy(() => import("./pages/reward_rule_blocks"))
);
const RewardRules = Loadable(lazy(() => import("./pages/reward_rules")));
const PlansApprovals = Loadable(lazy(() => import("./pages/plans_approvals")));

const Roles = Loadable(lazy(() => import("./pages/roles")));
const Columns = Loadable(lazy(() => import("./pages/settings/columns")));
const BrowseButtons = Loadable(
  lazy(() => import("./pages/browse/BrowseButtons"))
);
const BrowseCharts = Loadable(
  lazy(() => import("./pages/browse/BrowseCharts"))
);
const BrowseColors = Loadable(
  lazy(() => import("./pages/browse/BrowseColors"))
);
const BrowseDetailLists = Loadable(
  lazy(() => import("./pages/browse/BrowseDetailLists"))
);
const BrowseForms = Loadable(lazy(() => import("./pages/browse/BrowseForms")));
const BrowseGridLists = Loadable(
  lazy(() => import("./pages/browse/BrowseGridLists"))
);
const BrowseGroupedLists = Loadable(
  lazy(() => import("./pages/browse/BrowseGroupedLists"))
);
const BrowseInputs = Loadable(
  lazy(() => import("./pages/browse/BrowseInputs"))
);
const BrowseModals = Loadable(
  lazy(() => import("./pages/browse/BrowseModals"))
);
const BrowseQuickStats = Loadable(
  lazy(() => import("./pages/browse/BrowseQuickStats"))
);
const BrowseTables = Loadable(
  lazy(() => import("./pages/browse/BrowseTables"))
);
const BrowseTypography = Loadable(
  lazy(() => import("./pages/browse/BrowseTypography"))
);

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const PasswordRecovery = Loadable(
  lazy(() => import("./pages/authentication/PasswordRecovery"))
);
const PasswordReset = Loadable(
  lazy(() => import("./pages/authentication/PasswordReset"))
);
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const VerifyCode = Loadable(
  lazy(() => import("./pages/authentication/VerifyCode"))
);

const Excel = Loadable(lazy(() => import("./pages/excel")));

const Ubyport = Loadable(lazy(() => import("./pages/ubyport")));

// Blog pages

const BlogPostCreate = Loadable(
  lazy(() => import("./pages/blog/BlogPostCreate"))
);
const BlogPostDetails = Loadable(
  lazy(() => import("./pages/blog/BlogPostDetails"))
);
const BlogPostList = Loadable(lazy(() => import("./pages/blog/BlogPostList")));

// Dashboard pages

const Account = Loadable(lazy(() => import("./pages/dashboard/Account")));
const Analytics = Loadable(lazy(() => import("./pages/dashboard/Analytics")));
const Calendar = Loadable(lazy(() => import("./pages/dashboard/Calendar")));
const Chat = Loadable(lazy(() => import("./pages/dashboard/Chat")));
const CustomerDetails = Loadable(
  lazy(() => import("./pages/dashboard/CustomerDetails"))
);
const CustomerEdit = Loadable(
  lazy(() => import("./pages/dashboard/CustomerEdit"))
);
const CustomerList = Loadable(
  lazy(() => import("./pages/dashboard/CustomerList"))
);
const Finance = Loadable(lazy(() => import("./pages/dashboard/Finance")));
const InvoiceDetails = Loadable(
  lazy(() => import("./pages/dashboard/InvoiceDetails"))
);
const InvoiceList = Loadable(
  lazy(() => import("./pages/dashboard/InvoiceList"))
);
const Kanban = Loadable(lazy(() => import("./pages/dashboard/Kanban")));
const Vacancies = Loadable(lazy(() => import("./pages/dashboard/Vacancies")));
const Mail = Loadable(lazy(() => import("./pages/dashboard/Mail")));
const OrderDetails = Loadable(
  lazy(() => import("./pages/dashboard/OrderDetails"))
);
const OrderList = Loadable(lazy(() => import("./pages/dashboard/OrderList")));
const Overview = Loadable(lazy(() => import("./pages/dashboard/Overview3")));
const ProductCreate = Loadable(
  lazy(() => import("./pages/dashboard/ProductCreate"))
);
const ProductList = Loadable(
  lazy(() => import("./pages/dashboard/ProductList"))
);

// Docs pages

const Docs = Loadable(lazy(() => import("./pages/Docs")));

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

// Projects pages

const ProjectBrowse = Loadable(
  lazy(() => import("./pages/dashboard/ProjectBrowse"))
);
const ProjectCreate = Loadable(
  lazy(() => import("./pages/dashboard/ProjectCreate"))
);
const ProjectDetails = Loadable(
  lazy(() => import("./pages/dashboard/ProjectDetails"))
);

// Social pages

const SocialFeed = Loadable(lazy(() => import("./pages/dashboard/SocialFeed")));
const SocialProfile = Loadable(
  lazy(() => import("./pages/dashboard/SocialProfile"))
);

// Other pages

const Checkout = Loadable(lazy(() => import("./pages/Checkout")));
const Contact = Loadable(lazy(() => import("./pages/Contact")));
const Home = Loadable(lazy(() => import("./pages/Home2")));
const Registration = Loadable(lazy(() => import("./pages/Register2")));
const Pricing = Loadable(lazy(() => import("./pages/Pricing")));

const Audit = Loadable(lazy(() => import("./components/audit")));
const LabourOffices = Loadable(
  lazy(() => import("./pages/labour_office/labourOffices"))
);
const LabourOfficeNotifications = Loadable(
  lazy(() => import("./pages/labour_office/laboutOfficeNotifications"))
);

const AccommodationsDebug = Loadable(
  lazy(() => import("./pages/maintenance/Accommodations.js"))
);
const AssignmentsDebug = Loadable(
  lazy(() => import("./pages/maintenance/Assignments.js"))
);
const MonthleDataDebug = Loadable(
  lazy(() => import("./pages/maintenance/MonthlyData.js"))
);
const MeetingRecords = Loadable(lazy(() => import("./pages/meeting_records")));

const ReportVacancies = Loadable(
  lazy(() => import("./pages/reports/reportVacancies"))
);

const CustomerReports = Loadable(
  lazy(() => import("./pages/customer_reports"))
);

const ReportAttendance = Loadable(
  lazy(() => import("./pages/reports/reportAttendance"))
);

const ReportCompanies = Loadable(
  lazy(() => import("./pages/reports/reportCompanies"))
);

const ReportWallet = Loadable(
  lazy(() => import("./pages/reports/reportWallet"))
);
const VirtualWalletPayments = Loadable(
  lazy(() => import("./pages/virtual_wallet_payments"))
);

//Clean architecture start

const ViewPageEmploymentDepartmentInfosRoute =
  ViewPageEmploymentDepartmentInfos.Execute(); /*React.createElement( 
	Suspense,
	{
		fallback: React.createElement(  
			LoadingScreen,
			{
				
			},
			[
			
			]
		)
	},
	[

		ViewPageEmploymentDepartmentInfos.Execute( )

	]
);*/

const ViewPageEmploymentDepartmentNoticesRoute =
  ViewPageEmploymentDepartmentNotices.Execute(); /*React.createElement( 
	Suspense,
	{
		fallback: React.createElement(  
			LoadingScreen,
			{
				
			},
			[
			
			]
		)
	},
	[

		ViewPageEmploymentDepartmentNotices.Execute( )

	]
);*/

//Clean architecture end

var dashboard_children = [
  {
    path: "/maintenance_accommodation",
    element: <AccommodationsDebug />,
  },
  {
    path: "/maintenance_assignments",
    element: <AssignmentsDebug />,
  },
  {
    path: "/maintenance_monthly_data",
    element: <MonthleDataDebug />,
  },
  {
    path: "/",
    element: <Overview />,
  },
  {
    path: "/washing_machines",
    element: <WashingMachines />,
  },
  {
    path: "/labour_offices",
    element: <LabourOffices />,
  },
  {
    path: "/labour_office_notifications",
    element: <LabourOfficeNotifications />,
  },
  {
    path: "users",
    element: <Users type="all" />,
  },
  {
    path: "users_awa",
    element: <Users type="awa" />,
  },
  {
    path: "suppliers",
    element: <Suppliers type="suppliers" />,
  },
  {
    path: "supplier_invoices",
    element: <SupplierInvoices />,
  },
  {
    path: "supplier_account_numbers",
    element: <SupplierAccountNumbers />,
  },

  {
    path: "roles",
    element: <Roles />,
  },
  {
    path: "account_numbers",
    element: <AccountNumbers />,
  },
  {
    path: "projects",
    element: <Projects />,
  },
  {
    path: "sub_projects",
    element: <SubProjects />,
  },
  {
    path: "cars",
    element: <Cars />,
  },
  {
    path: "place_addresses",
    element: <PlaceAdresses />,
  },
  {
    path: "medical_checkup_types",
    element: <CheckupsTypes />,
  },
  {
    path: "additional_texts",
    element: <AdditionalTexts />,
  },
  {
    path: "expense_types",
    element: <ExpenseTypes />,
  },
  {
    path: "customers",
    element: <Customers />,
  },
  {
    path: "customers_firm",
    element: <CustomersFirms />,
  },
  {
    path: "customer_states",
    element: <CustomerStates />,
  },
  {
    path: "customer_start_requirement_conditions",
    element: <CustomerRequirementConditions />,
  },
  {
    path: "all_customer_requirements",
    element: <AllCustomerRequirements />,
  },
  {
    path: "received_invoices",
    element: <ReceivedInvoices />,
  },
  {
    path: "pending_invoices",
    element: <PendingInvoices />,
  },

  {
    path: "work_places",
    element: <WorkPlaces />,
  },

  {
    path: "agencies",
    element: <Agencies />,
  },

  {
    path: "accommodations",
    element: <Accommodations />,
  },

  {
    path: "notifications",
    element: <Notifications />,
  },

  {
    path: "ondemand_log",
    element: <Audit type="employees" id="0" />,
  },

  {
    path: "invoice_payers",
    element: <InvoicePayers />,
  },
  {
    path: "for_approval_invoices",
    element: <ForApprovalInvoices />,
  },

  {
    path: "invoice_suppliers",
    element: <InvoiceSuppliers />,
  },
  {
    path: "pokladni_types",
    element: <PokladniTypes />,
  },

  {
    path: "dochazka_statuses",
    element: <DochazkaStatuses />,
  },
  {
    path: "fine_types",
    element: <FineTypes />,
  },
  {
    path: "customer_legals",
    element: <CustomerLegal />,
  },

  {
    path: "employment_department_infos",
    element: ViewPageEmploymentDepartmentInfosRoute,
  },

  {
    path: "employment_department_notices",
    element: ViewPageEmploymentDepartmentNoticesRoute,
  },

  {
    path: "permissions",
    element: <Permissions />,
  },
  {
    path: "permissions_templates",
    element: <PermissionsTemplates />,
  },
  {
    path: "permissions_mass",
    element: <MassPermissions />,
  },
  {
    path: "employees_columns",
    element: <Columns model="employees" />,
  },
  {
    path: "regular_payments_columns",
    element: <Columns model="regular_payments" />,
  },
  {
    path: "payments_columns",
    element: <Columns model="payments" />,
  },
  {
    path: "downpayments_columns",
    element: <Columns model="downpayments" />,
  },
  {
    path: "employments_columns",
    element: <Columns model="employments" />,
  },
  {
    path: "received_invoices_columns",
    element: <Columns model="received_invoices" />,
  },
  {
    path: "issued_invoices_columns",
    element: <Columns model="issued_invoices" />,
  },
  {
    path: "account_numbers_columns",
    element: <Columns model="account_numbers" />,
  },
  {
    path: "invoice_items_columns",
    element: <Columns model="invoice_items" />,
  },
  {
    path: "invoice_item_names_columns",
    element: <Columns model="invoice_item_names" />,
  },
  {
    path: "coordinator_expences_types_columns",
    element: <Columns model="coordinator_expences_types" />,
  },
  {
    path: "coordinators_expences_columns",
    element: <Columns model="coordinators_expences" />,
  },
  {
    path: "coordinator_money_requests_columns",
    element: <Columns model="coordinator_money_requests" />,
  },
  {
    path: "projects_columns",
    element: <Columns model="projects" />,
  },
  {
    path: "sub_projects_columns",
    element: <Columns model="sub_projects" />,
  },
  {
    path: "cars_columns",
    element: <Columns model="cars" />,
  },
  {
    path: "place_addresses_columns",
    element: <Columns model="place_addresses" />,
  },
  {
    path: "medical_checkup_types_columns",
    element: <Columns model="medical_checkup_types" />,
  },
  {
    path: "medical_checkups_columns",
    element: <Columns model="medical_checkups" />,
  },
  {
    path: "medical_checkups",
    element: <MedicalCheckups />,
  },
  {
    path: "accounting_requests",
    element: <AccountingRequests />,
  },
  {
    path: "assignments_columns",
    element: <Columns model="assignments" />,
  },
  {
    path: "visas_columns",
    element: <Columns model="visas" />,
  },
  {
    path: "wire_transfers_columns",
    element: <Columns model="wire_transfers" />,
  },
  {
    path: "additional_texts_columns",
    element: <Columns model="additional_texts" />,
  },
  {
    path: "communications_columns",
    element: <Columns model="communications" />,
  },
  {
    path: "departments_columns",
    element: <Columns model="departments" />,
  },
  {
    path: "customer_departments_columns",
    element: <Columns model="customer_departments" />,
  },
  {
    path: "employee_customers_columns",
    element: <Columns model="employee_customers" />,
  },
  {
    path: "customers_columns",
    element: <Columns model="customers" />,
  },
  {
    path: "customer_interactions_columns",
    element: <Columns model="customer_interactions" />,
  },
  {
    path: "suppliers_columns",
    element: <Columns model="suppliers" />,
  },
  {
    path: "supplier_invoices_columns",
    element: <Columns model="supplier_invoices" />,
  },
  {
    path: "supplier_account_numbers_columns",
    element: <Columns model="supplier_account_numbers" />,
  },
  {
    path: "agency_accommodations_columns",
    element: <Columns model="agency_accommodations" />,
  },

  {
    path: "fines_columns",
    element: <Columns model="fines" />,
  },

  {
    path: "rests_columns",
    element: <Columns model="rests" />,
  },
  {
    path: "rests_ubytovatel_columns",
    element: <Columns model="pokuty_ubytovatel" />,
  },
  {
    path: "interactions_columns",
    element: <Columns model="interactions" />,
  },
  {
    path: "clients_columns",
    element: <Columns model="clients" />,
  },
  {
    path: "accommodations_columns",
    element: <Columns model="accommodations" />,
  },
  {
    path: "ubyports_columns",
    element: <Columns model="ubyports" />,
  },
  {
    path: "customer_legals_columns",
    element: <Columns model="customer_legals" />,
  },
  {
    path: "customer_states_columns",
    element: <Columns model="customer_states" />,
  },
  {
    path: "customer_start_requirement_conditions_columns",
    element: <Columns model="customer_start_requirement_conditions" />,
  },
  {
    path: "customer_start_requirements_columns",
    element: <Columns model="customer_start_requirements" />,
  },
  {
    path: "customer_start_requirement_propositions_columns",
    element: <Columns model="customer_start_requirement_propositions" />,
  },
  {
    path: "employment_department_infos_columns",
    element: <Columns model="employment_department_infos" />,
  },
  {
    path: "employment_department_notices_columns",
    element: <Columns model="employment_department_notices" />,
  },
  {
    path: "accounting_requests_columns",
    element: <Columns model="accounting_requests" />,
  },
  {
    path: "feedbacks_columns",
    element: <Columns model="feedbacks" />,
  },
  {
    path: "daily_coordinator_reports_columns",
    element: <Columns model="dailycoordinator_reports" />,
  },
  {
    path: "customer_reports_columns",
    element: <Columns model="customer_reports" />,
  },
  {
    path: "meeting_records_columns",
    element: <Columns model="meeting_records" />,
  },
  {
    path: "newsletters_columns",
    element: <Columns model="newsletters" />,
  },
  {
    path: "rewards",
    element: <Rewards />,
  },

  {
    path: "forms",
    element: <Forms />,
  },

  {
    path: "translations/ru",
    element: <Translations language="ru" />,
  },

  {
    path: "mail_templates",
    element: <MailTemplates />,
  },
  {
    path: "payment",
    element: <PaymentsBk />,
  },
  {
    path: "payments",
    element: <Payments />,
  },
  {
    path: "regular_payments",
    element: <RegularPayments />,
  },
  {
    path: "company",
    element: <Companies />,
  },

  {
    path: "your-company",
    element: <CompanyEmployees />,
  },
  {
    path: "documents/tags",
    element: <DocumentTags />,
  },
  {
    path: "documents",
    element: <Documents />,
  },

  {
    path: "report_dochazky",
    element: <ReportDochazky />,
  },
  {
    path: "report_accommodation",
    element: <ReportAccommodation />,
  },
  {
    path: "report_reception",
    element: <ReportReception />,
  },
  {
    path: "report_reservation",
    element: <ReportReservation />,
  },
  {
    path: "report_items",
    element: <ReportInventura />,
  },
  {
    path: "report_collection",
    element: <ReportCollection />,
  },
  {
    path: "report_requests",
    element: <ReportRequests />,
  },
  {
    path: "report_fines",
    element: <ReportRequests fines={true} />,
  },
  {
    path: "reservation",
    element: <ReservForm />,
  },
  {
    path: "personal_report",
    element: <PersonalReport />,
  },
  {
    path: "inventory_items",
    element: <InventoryItems />,
  },
  {
    path: "report_people",
    element: <ReportPeople />,
  },
  {
    path: "requests_types",
    element: <RequestsTypes />,
  },
  {
    path: "report_moving",
    element: <ReportMoving />,
  },
  {
    path: "report_recruitment",
    element: <ReportRecruitment />,
  },
  {
    path: "downpayments",
    element: <Downpayments />,
  },
  {
    path: "downpayments_requests",
    element: <DownpaymentsRequests />,
  },
  {
    path: "hours_plans",
    element: <ReportHours />,
  },
  {
    path: "other_settings",
    element: <OtherSettings />,
  },
  {
    path: "excel",
    element: <Excel />,
  },
  {
    path: "ubyport",
    element: <Ubyport />,
  },
  {
    path: "departments",
    element: <Departments model="departments" />,
  },
  {
    path: "customer_departments",
    element: <CustomerDepartments model="customer_departments" />,
  },
  {
    path: "employee_customers",
    element: <EmployeeCustomers model="employee_customers" />,
  },
  {
    path: "employees",
    element: <Employees model="employees" typ="employees" key="employees" />,
  },
  {
    path: "employees_to_sign",
    element: <Employees model="employees" typ="signOnly" key="signOnly" />,
  },
  {
    path: "recruitment",
    element: (
      <Employees model="employees" typ="recruitment" key="recruitment" />
    ),
  },
  // {
  //   path: "rozdeleni",
  //   element: <Employees model="employees" typ="rozdeleni" key="rozdeleni" />,
  // },

  {
    path: "rewards_report",
    element: <ReportRewards />,
  },
  {
    path: "rewards_report_new",
    element: <ReportRewardNew />,
  },
  {
    path: "bonus_report",
    element: <ReportBonus />,
  },
  {
    path: "provision_report",
    element: <ReportProvision />,
  },
  {
    path: "cashier_report",
    element: <ReportCashier />,
  },
  {
    path: "commission_report",
    element: <ReportCommission />,
  },
  {
    path: "supplier_report",
    element: <SupplierReport />,
  },
  {
    path: "salaries_report",
    element: <ReportSalaries />,
  },
  {
    path: "minus_salaries_report",
    element: <MinusSalaries />,
  },
  {
    path: "report_mezd",
    element: <ReportMezd />,
  },
  {
    path: "wire_transfers",
    element: <WireTransfers />,
  },
  {
    path: "invoice_item_names",
    element: <InvoiceItemNames />,
  },
  {
    path: "invoice_items",
    element: <InvoiceItems />,
  },
  {
    path: "issued_invoices_report",
    element: <IssuedInvoiceReport />,
  },
  {
    path: "issued_invoices",
    element: <IssuedInvoices />,
  },
  {
    path: "accommodation_statistic",
    element: <AccommodationStatistic />,
  },
  {
    path: "coordinator_expences_types",
    element: <CoordinatorExpencesTypes />,
  },
  {
    path: "coordinator_expences_report",
    element: <CoordinatorExpencesReport />,
  },
  {
    path: "coordinators_expences",
    element: <CoordinatorExpences />,
  },
  {
    path: "coordinator_money_requests",
    element: <CoordinatorMoneyRequests />,
  },
  {
    path: "clients",
    element: <Clients />,
  },

  {
    path: "account",
    element: <Account />,
  },
  {
    path: "analytics",
    element: <Analytics />,
  },
  {
    path: "calendar",
    element: <Calendar />,
  },
  {
    path: "feedbacks",
    element: <Feedbacks />,
  },
  {
    path: "daily_coordinator_reports/",
    element: <CoordinatorReports />,
  },
  {
    path: "daily_coordinator_reports/:customer_id",
    element: <CoordinatorReports />,
  },
  {
    path: "meeting_records",
    element: <MeetingRecords />,
  },
  {
    path: "customer_reports",
    element: <CustomerReports />,
  },
  {
    path: "vacancies_report",
    element: <ReportVacancies />,
  },
  {
    path: "attendance_report",
    element: <ReportAttendance />,
  },
  {
    path: "companies_report",
    element: <ReportCompanies />,
  },
  {
    path: "report_wallet",
    element: <ReportWallet />,
  },
  {
    path: "virtual_wallet_payments",
    element: <VirtualWalletPayments />,
  },
  {
    path: "phone_calls",
    element: <PhoneCalls />,
  },
  {
    path: "newsletters",
    element: <Newsletters />,
  },
  {
    path: "report_suppliers",
    element: <ReportSuppliers />,
  },
  {
    path: "reward_rule_blocks",
    element: <RewardRuleBlocks type={"reward"} key={"reward"} />,
  },
  {
    path: "bonus_rule_blocks",
    element: <RewardRuleBlocks type={"bonus"} key={"bonus"} />,
  },
  {
    path: "reward_rules",
    element: <RewardRules type={"reward"} key={"reward"} />,
  },
  {
    path: "bonus_rules",
    element: <RewardRules type={"bonus"} key={"bonus"} />,
  },
  {
    path: "plans_approvals",
    element: <PlansApprovals />,
  },
  {
    path: "chat",
    children: [
      {
        path: "/",
        element: <Chat />,
      },
      {
        path: "new",
        element: <Chat />,
      },
      {
        path: ":threadKey",
        element: <Chat />,
      },
    ],
  },
  {
    path: "customers_list",
    children: [
      {
        path: "/",
        element: <CustomerList />,
      },
      {
        path: ":customerId",
        element: <CustomerDetails />,
      },
      {
        path: ":customerId/edit",
        element: <CustomerEdit />,
      },
    ],
  },
  {
    path: "invoices",
    children: [
      {
        path: "/",
        element: <InvoiceList />,
      },
      {
        path: ":invoiceId",
        element: <InvoiceDetails />,
      },
    ],
  },
  {
    path: "kanban",
    element: <Kanban />,
  },
  {
    path: "vacancies",
    element: <Vacancies />,
  },
  {
    path: "mail",
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard/mail/all" replace />,
      },
      {
        path: "label/:customLabel",
        element: <Mail />,
      },
      {
        path: "label/:customLabel/:emailId",
        element: <Mail />,
      },
      {
        path: ":systemLabel",
        element: <Mail />,
      },
      {
        path: ":systemLabel/:emailId",
        element: <Mail />,
      },
    ],
  },
  {
    path: "orders",
    children: [
      {
        path: "/",
        element: <OrderList />,
      },
      {
        path: ":orderId",
        element: <OrderDetails />,
      },
    ],
  },
  {
    path: "finance",
    element: <Finance />,
  },
  {
    path: "products",
    children: [
      {
        path: "/",
        element: <ProductList />,
      },
      {
        path: "new",
        element: <ProductCreate />,
      },
    ],
  },
  {
    path: "projects",
    children: [
      {
        path: "browse",
        element: <ProjectBrowse />,
      },
      {
        path: "new",
        element: <ProjectCreate />,
      },
      {
        path: ":projectId",
        element: <ProjectDetails />,
      },
    ],
  },
  {
    path: "social",
    children: [
      {
        path: "feed",
        element: <SocialFeed />,
      },
      {
        path: "profile",
        element: <SocialProfile />,
      },
    ],
  },
];

dashboard_children.push({
  path: "dochazka/*",
  element: <Employees typ="dochazka" key="dochazka" />,
});
dashboard_children.push({
  path: "agentura/*",
  element: <Employees typ="agentura" key="agentura" />,
});

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login-unguarded",
        element: <Login />,
      },
      {
        path: "password-recovery",
        element: <PasswordRecovery />,
      },
      {
        path: "password-reset",
        element: <PasswordReset />,
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Registration type={"worker"} />
          </GuestGuard>
        ),
      },
      {
        path: "register-unguarded",
        element: <Registration type={"worker"} />,
      },
      {
        path: "verify-code",
        element: <VerifyCode />,
      },
    ],
  },

  {
    path: "blog",
    element: <BlogLayout />,
    children: [
      {
        path: "/",
        element: <BlogPostList />,
      },
      {
        path: "new",
        element: <BlogPostCreate />,
      },
      {
        path: ":postId",
        element: <BlogPostDetails />,
      },
    ],
  },
  {
    path: "contact",
    element: <Contact />,
  },

  {
    path: "dashboard",
    element: (
      <AuthGuard expected={"true"} dest_err={"/"}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: dashboard_children,
  },
  {
    path: "docs",
    element: <DocsLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/docs/overview/welcome" replace />,
      },
      {
        path: "*",
        element: <Docs />,
      },
    ],
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: (
          <AuthGuard expected={"false"} dest_err={"/dashboard"}>
            <Home />
          </AuthGuard>
        ),
      },
      {
        path: "vacancy-info/:vacancyId",
        element: <VacancyInfo />,
      },
      {
        path: "unsubscribe_confirm/:email",
        element: <UnsubscribeConfirm />,
      },
      {
        path: "registration/*",
        element: <Registration type={"worker"} />,
      },
      {
        path: "registrace_dodavatele",
        element: (
          <AuthGuard expected={"false"} dest_err={"/dashboard"}>
            <Registration type={"supplier"} />
          </AuthGuard>
        ),
      },
      {
        path: "browse",
        element: <BrowseLayout />,
        children: [
          {
            path: "/",
            element: <Browse />,
          },
          {
            path: "/buttons",
            element: <BrowseButtons />,
          },
          {
            path: "/inputs",
            element: <BrowseInputs />,
          },
          {
            path: "/charts",
            element: <BrowseCharts />,
          },
          {
            path: "/colors",
            element: <BrowseColors />,
          },
          {
            path: "/data-display/detail-lists",
            element: <BrowseDetailLists />,
          },
          {
            path: "/data-display/quick-stats",
            element: <BrowseQuickStats />,
          },
          {
            path: "/data-display/tables",
            element: <BrowseTables />,
          },
          {
            path: "/forms",
            element: <BrowseForms />,
          },
          {
            path: "/modals",
            element: <BrowseModals />,
          },
          {
            path: "/lists/grouped-lists",
            element: <BrowseGroupedLists />,
          },
          {
            path: "/lists/grid-lists",
            element: <BrowseGridLists />,
          },
          {
            path: "/typography",
            element: <BrowseTypography />,
          },
        ],
      },
      {
        path: "checkout",
        element: <Checkout />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "401",
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
