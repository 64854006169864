import { useState, useEffect } from "react";
import { Formik, Field, Form, useFormik } from "formik";
import * as Yup from "yup";
import { MobileDatePicker, TimePicker } from "@material-ui/lab";
import Autocomplete from "@mui/material/Autocomplete";
import { Add as AddIcon } from "@material-ui/icons";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Alert,
  InputLabel,
  Button,
  IconButton,
  Box,
  Container,
  Typography,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Accordion,
} from "@material-ui/core";
import useSettings from "../../hooks/useSettings";
import { useTranslation } from "react-i18next";
import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";
import LocaleRu from "../../aggrid/locale.ru.js";
import { format } from "date-fns";

import HttpService from "../../services/HttpService.js";
import UrlService from "../../services/UrlService.js";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
import csLocale from "date-fns/locale/cs";

const localeMap = {
  ru: ruLocale,
  cs: csLocale,
};

const AccommodateForm = ({
  moving,
  data = {},
  handleCloseAccommodate,
  hostel_id = "",
  user_info = {},
  getRowdata = () => {},
  getMainTableData = () => {},
}) => {
  const { info: { employee_id } = {}, volná_místa = "" } = data;

  const {
    name,
    surname,
    id,
    date_of_birth,
    employeeId,
    jméno,
    příjmení,
    user,
  } = user_info;

  const { t } = useTranslation();
  var lt;
  if (t("cs") == "cs") lt = LocaleCs;
  if (t("cs") == "en") lt = LocaleEn;
  if (t("cs") == "ru") lt = LocaleRu;

  let lc = localStorage.getItem("language");
  if (lc == null || lc == "") lc = t("cs");
  const [locale, setLocale] = useState(lc);
  const [loctext, setloctext] = useState(lt);

  const { settings } = useSettings();
  const [people, setPeople] = useState([]);
  const [personFields, setPersonFields] = useState([0]);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [freeHostels, setFreeHostels] = useState([]);
  const [freeRooms, setFreeRooms] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [roomPrice, setRoomPrice] = useState();

  const policy_actions = JSON.parse(
    localStorage.getItem("policy_action_tree")
  ).agency_accommodations;

  const addPersonField = () => {
    const newPersonFields = [...personFields];
    newPersonFields.push(newPersonFields.length);
    setPersonFields(newPersonFields);
  };
  const removePersonField = (index) => {
    const newPersonFields = personFields.filter(
      (personField, i) => i !== index
    );
    const newPeople = formik.values.people.filter((item, i) => i !== index);
    formik.setFieldValue("people", newPeople);
    setPersonFields(newPersonFields);
  };

  const formik = useFormik({
    initialValues: {
      ubyport: false,
      rools: false,
      cash: false,
      hostel: hostel_id || data.hostel_id || "",
      room: data.room_id || "",
      date: new Date(),
      // time: new Date(),
      people: [{ id: data.employee_id || user || id || employeeId || "" }],
      price: data.price || "",
    },
    validationSchema: Yup.object({
      hostel: Yup.string().required(t("Povinné")),
      room: Yup.string().required(t("Povinné")),
      date: Yup.date().required(t("Povinné")),
      // time: Yup.date().required("Povinné"),
      price: Yup.number().test(
        "valid-price",
        t("Cenu lze změnit pouze o 200, 220, 250 nebo 270 Kč"),
        function (value) {
          return (
            value === data.price ||
            value === 200 ||
            value === 220 ||
            value == roomPrice ||
            value === 250 ||
            value == roomPrice ||
            value === 270
          );
        }
      ),
      people: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string().required(t("Povinné")),
          })
        )
        .required(t("Je vyžadována alespoň jedna osoba")),
      ubyport: Yup.boolean(),
      rools: Yup.boolean(),
      cash: Yup.boolean(),
    }),
    onSubmit: (values) => {
      handleOpen();
      console.log(values);
    },
  });
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const closeAllDialogs = () => {
    handleCloseAccommodate();
    handleClose();
    setOpenConfirm(false);
  };

  const handleSearch = (value) => {
    const currentId = formik.values.people[0]?.id || "";
    if (value.length > 2 && value.length < 5) {
      HttpService.get(
        UrlService.apiDomain() + `api/getAvailableUsers?search=${value}`,
        {
          id: currentId,
          employee_id: employee_id || "",
        }
      )
        .then((res) => {
          setPeople([...people, ...res.data]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleHostelSearch = (value) => {
    let url = policy_actions.includes("over_occupancy")
      ? `api/getAllHostels?search=${value}`
      : `api/getFreeHostels?search=${value}`;
    if (value.length > 2 && value.length < 5) {
      HttpService.get(UrlService.apiDomain() + url)
        .then((res) => {
          setFreeHostels(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleRoomsSearch = (hostel_id) => {
    let url = policy_actions.includes("over_occupancy")
      ? `api/getAllRooms/`
      : `api/getFreeRooms/`;

    if (hostel_id) {
      HttpService.get(UrlService.apiDomain() + url + hostel_id)
        .then((res) => {
          setFreeRooms(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    handleRoomsSearch(formik.values.hostel);
  }, []);

  useEffect(() => {
    if (freeRooms.length && formik.values.room) {
      const price =
        freeRooms.find((room) => room.id == formik.values.room)?.price || "";
      formik.setFieldValue("price", price);
    }
  }, [freeRooms, formik.values.room]);

  const handleAlertClose = () => {
    setShowAlert(false);
  };
  const sendData = async (values) => {
    setDisabledButton(true);
    handleClose();
    const data = {
      hostel_id: values.hostel,
      room_id: values.room,
      date: format(values.date, "yyyy-MM-dd"),
      // time: format(values.time, "HH:mm:ss"),
      people: values.people.map((person) => person.id),
      ubyport: values.ubyport,
      rools: values.rools,
      price: values.price,
      cash: values.cash,
      moving: moving,
    };
    try {
      const response = await HttpService.post(
        `${UrlService.apiDomain()}api/setHostel`,
        data
      );
      if (response.status != 200) {
        setAlertMessage(response.data.message);
        setShowAlert(true);
        setDisabledButton(false);
        return;
      } else {
        console.log("sendData", data);
        getRowdata();
        getMainTableData();
        formik.handleReset();
        setOpenConfirm(true);
        setDisabledButton(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 8,
      }}
    >
      <Container maxWidth={settings.compact ? "xl" : false}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography color="textPrimary" variant="h5">
              {t("Ubytovat")}
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={handleCloseAccommodate}>
              {t("Zavřít")}
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ p: 4 }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      disabled={Boolean(data.ubytovna) || Boolean(data.hostel)}
                      id={`hostel`}
                      name={`hostel`}
                      options={freeHostels}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        formik.setFieldValue(`hostel`, value?.id);
                        handleRoomsSearch(value?.id);
                      }}
                      onInputChange={(e, value) => handleHostelSearch(value)}
                      onBlur={formik.handleBlur}
                      value={
                        freeHostels.find(
                          (hostel) => hostel.id === formik.values.hostel
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={data.ubytovna || data.hostel || t("Ubytovna")}
                          error={
                            !!(formik.touched.hostel && formik.errors.hostel)
                          }
                          helperText={
                            formik.touched.hostel && formik.errors.hostel
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      disabled={Boolean(data.pokoj) || Boolean(data.room)}
                      id={`room`}
                      name={`room`}
                      options={freeRooms}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        formik.setFieldValue(`room`, value?.id); // 30.10
                        setRoomPrice(value?.price);
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        freeRooms.find(
                          (room) => room.id === formik.values.room // 30.10
                        ) || null
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Pokoj")}
                          error={!!(formik.touched.room && formik.errors.room)}
                          helperText={formik.touched.room && formik.errors.room}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={localeMap[locale]}
                  >
                    <MobileDatePicker
                      disabled={!policy_actions.includes("edit_date")}
                      label={t("Datum")}
                      inputFormat="dd/MM/yyyy"
                      value={formik.values.date}
                      onChange={(value) => {
                        formik.setFieldValue("date", value);
                      }}
                      onBlur={formik.handleBlur}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <TimePicker
                    ampm={false}
                    label={t("Čas")}
                    value={formik.values.time}
                    onChange={(value) => {
                      formik.setFieldValue("time", value);
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText=""
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  /> */}
                  <TextField
                    name="price"
                    label={t("Cena")}
                    disabled={!policy_actions.includes("price")}
                    variant="outlined"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.price}
                    error={!!(formik.touched.price && formik.errors.price)}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                </Grid>
                {personFields.map((personField, index) => (
                  <Grid item xs={12} key={personField} sx={{ display: "flex" }}>
                    <FormControl fullWidth>
                      <Autocomplete
                        disabled={
                          Boolean(jméno) || Boolean(name) || (data.name && true)
                        }
                        id={`people[${personField}].id`}
                        name={`people[${personField}].id`}
                        options={people}
                        getOptionLabel={(option) =>
                          `${option.name} ${option.surname}, ${option?.date_of_birth}, ${option?.code}, Číslo pasu: ${option?.passport_number}, ${option?.customer}`
                        }
                        onChange={(e, value) => {
                          formik.setFieldValue(
                            `people[${personField}].id`,
                            value?.id
                          );
                        }}
                        onInputChange={(e, value) => handleSearch(value)}
                        onBlur={formik.handleBlur}
                        value={
                          people.find(
                            (person) =>
                              person.id ===
                              formik.values.people[personField]?.id
                          ) || null
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              (jméno &&
                                `${jméno} ${příjmení} ${date_of_birth}`) ||
                              (name && `${name} ${surname} ${date_of_birth}`) ||
                              (data.name &&
                                `${data.name} ${data.surname} ${data.date_of_birth}`) ||
                              t("Zadejte první tři písmena příjmení")
                            }
                            error={
                              !!(
                                formik.touched.people &&
                                formik.touched.people[personField] &&
                                formik.touched.people[personField].id &&
                                formik.errors.people &&
                                formik.errors.people[personField] &&
                                formik.errors.people[personField].id
                              )
                            }
                            helperText={
                              formik.touched.people &&
                              formik.touched.people[personField] &&
                              formik.touched.people[personField].id &&
                              formik.errors.people &&
                              formik.errors.people[personField] &&
                              formik.errors.people[personField].id
                            }
                          />
                        )}
                      />
                    </FormControl>
                    {index > 0 && (
                      <IconButton onClick={() => removePersonField(index)}>
                        <RemoveIcon />
                      </IconButton>
                    )}
                    {index === 0 && (
                      <IconButton
                        disabled={
                          (!policy_actions.includes("over_occupancy") &&
                            volná_místa === personFields.length) ||
                          Boolean(jméno) ||
                          (name && true)
                        }
                        onClick={addPersonField}
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                  </Grid>
                ))}
              </Grid>
              <Grid item>
                <FormControlLabel
                  label={t("Ubyport")}
                  control={
                    <Checkbox
                      id="ubyport"
                      name="ubyport"
                      type="checkbox"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.ubyport}
                    />
                  }
                />
                <FormControlLabel
                  label={t("Podepsat ubytovací řád")}
                  control={
                    <Checkbox
                      id="rools"
                      name="rools"
                      type="checkbox"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.rools}
                    />
                  }
                />
                <FormControlLabel
                  label={t("Bydlí za hotovost")}
                  control={
                    <Checkbox
                      id="cash"
                      name="cash"
                      type="checkbox"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.cash}
                    />
                  }
                />
              </Grid>
              {showAlert && (
                <Alert
                  severity="error"
                  onClose={handleAlertClose}
                  open={showAlert}
                >
                  {alertMessage}
                </Alert>
              )}
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  {t("Ubytovat")}
                </Button>
              </Grid>
            </Grid>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("Potvrďte ubotování")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t("Opravdu si chcete ubytovat člověka?")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {t("Zrušení")}
                </Button>
                <Button
                  color="primary"
                  disabled={disabledButton}
                  autoFocus={true}
                  onClick={() => sendData(formik.values)}
                >
                  {t("Ubytovat")}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={openConfirm} onClose={closeAllDialogs}>
              <DialogTitle>{t("Potvrzení ubytovani")}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t("Ubytovani úspěšně vytvořena")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeAllDialogs} color="primary">
                  {t("Zavřít")}
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default AccommodateForm;
