import { createContext, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { getLocalJson } from "../utils/localStorageUtills";

export const FilterContext = createContext(null);

export const FilterProvider = (props) => {
  const { children } = props;
  const [companyFilter, setCompanyFilter] = useState(null);
  const [buildingFilter, setBuildingFilter] = useState(null);
  const [projectFilter, setProjectFilter] = useState(null);
  const [subProjectFilter, setSubProjectFilter] = useState(null);

  const [allowedCompanies, setAllowedCompanies] = useState(null);
  const [allowedBuildings, setAllowedBuildings] = useState(null);
  const [allowedCategories, setAllowedCategories] = useState(null);
  const [allowedProjects, setAllowedProjects] = useState(null);
  const [allowedSuppliers, setAllowedSuppliers] = useState(null);
  const [allowedCustomers, setAllowedCustomers] = useState(null);
  const [allowedSubProjects, setAllowedSubProjects] = useState(null);

  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);

  

  const [pronajimatel, setPronajimatel] = useState(1);

  const { user } = useAuth();
  const [clearRequested, setClearRequested] = useState(false);

  useEffect(() => {
    setBuildingFilter(null);
  }, [companyFilter]);

  useEffect(() => {
    if (user) {
      let toRead = [
        { from: user.allowed_companies, set: setAllowedCompanies },
        { from: user.allowed_buildings, set: setAllowedBuildings },
        { from: user.allowed_categories, set: setAllowedCategories },
        { from: user.allowed_projects, set: setAllowedProjects },
        { from: user.allowed_suppliers, set: setAllowedSuppliers },
        { from: user.allowed_customers, set: setAllowedCustomers },
        { from: user.allowed_sub_projects, set: setAllowedSubProjects },
      ];

      for (const item of toRead) {
        let temp =
          item.from
            ?.toString()
            .split("//")
            .map((n) => parseInt(n)) ?? null;

        if (temp && temp.includes(0)) {
          temp = temp.filter((n) => n !== 0);
          temp.push(null);
        }

        item.set(temp);
      }
    }
  }, [user]);

  const filterData = (
    data,
    model,
    companyF,
    buildingF,
    projectF,
    subProjectF
  ) => {
    let categoryF = allowedCategories ?? [];
    let projectsF = allowedProjects ?? [];
    let subProjectsF = allowedSubProjects ?? [];
    let suppliersF = allowedSuppliers ?? [];
    let customersF = allowedCustomers ?? [];

    let predicate;
    switch (model) {
      case "companies":
        predicate = (d) => !companyF || companyF.includes(d.id);
        break;
      case "finance_agencies":
        predicate = (d) => !companyF || companyF.includes(d.id);
        break;
      case "buildings":
        predicate = (d) =>
          (!buildingF || buildingF.includes(d.id)) &&
          (!companyF || companyF.includes(d.company_id));
        break;
      case "projects":
        predicate = (d) => {
          if (!projectF || projectF.includes(d.id)) {
            if (
              !companyF ||
              !d.company_ids ||
              d.company_ids.split("//").some((r) => companyF.includes(+r))
            ) {
              return true;
            }
          }
          return false;
        };
        break;
      default:
        predicate = (d) => {
          let facilities = JSON.parse(localStorage.getItem("facilities"));
          let buildings = JSON.parse(localStorage.getItem("buildings"));
          let rents = JSON.parse(localStorage.getItem("rents"));
          let account_numbers = JSON.parse(
            localStorage.getItem("account_numbers")
          );

          let rent_id = d.rent_id;
          let building_id = d.building_id;
          let company_id = d.company_id || d.agency_id;
          let facility_id = d.facility_id;
          let account_number_id =
            d.account_number ??
            d.account_number_id ??
            d.to_account_number ??
            d.account_id;
          let company_ids = d.company_ids;

          let project_id = d.project_id;
          let project_ids = d.project_ids;

          let sub_project_id = d.sub_project_id;
          let sub_project_ids = d.sub_project_ids;

          if (rent_id) {
            let rent = rents.find((r) => r.id === rent_id);
            facility_id = rent?.facility_id;
          }
          if (facility_id) {
            let facility = facilities.find((f) => f.id === facility_id);
            building_id = facility?.building_id;
          }
          if (building_id) {
            let building = buildings.find((b) => b.id === building_id);
            company_id = building?.company_id;
          }
          if (!company_id && !company_ids && account_number_id) {
            let account_number = account_numbers.find(
              (c) => c.id === account_number_id
            );
            if (account_number) {
              company_id = account_number.company_id;
            }
          }
          if (company_ids) {
            company_ids = company_ids.split("//").map((i) => {
              return parseInt(i);
            });
          }
          if (project_ids) {
            project_ids = project_ids.split("//").map((i) => {
              return parseInt(i);
            });
          }
          if (sub_project_ids) {
            sub_project_ids = sub_project_ids.split("//").map((i) => {
              return parseInt(i);
            });
          }

          if (rent_id === "") rent_id = null;
          if (building_id === "") building_id = null;
          if (company_id === "") company_id = null;
          if (project_id === "") project_id = null;
          if (facility_id === "") facility_id = null;
          if (account_number_id === "") account_number_id = null;
          if (company_ids === "") company_ids = null;
          if (company_ids === null) company_ids = [null];
          if (project_ids === "") project_ids = null;
          if (project_ids === null) project_ids = [null];
          if (sub_project_id === "") sub_project_id = null;
          if (sub_project_ids === "") sub_project_ids = null;
          if (sub_project_ids === null) sub_project_ids = [null];

          if (model === "issued_invoices" || model === "received_invoices") {
            let date = d.date_of_tax_execution;
            if (date) {
              let [paymentYear, paymentMonth] = date.split("-");
              if (year) {
                if (paymentYear !== year) {
                  return false;
                }
              }
              if (month) {
                if (paymentMonth !== month) {
                  return false;
                }
              }
            }
          }

          if (model === "rents") {
            let fromDate = d.from_date;
            let toDate = d.to_date;
            if (
              d.ukonceni_najmu &&
              d.ukonceni_najmu !== "" &&
              d.ukonceni_najmu !== "0000-00-00"
            ) {
              toDate = d.ukonceni_najmu;
            }
            if (fromDate) {
              let [fromYear, fromMonth] = fromDate.split("-");
              if (year) {
                if (fromYear > year) {
                  return false;
                } else if (month && fromYear === year) {
                  if (fromMonth > month) {
                    return false;
                  }
                }
              }
            }
            if (toDate) {
              let [toYear, toMonth] = toDate.split("-");
              if (year) {
                if (toYear < year) {
                  return false;
                } else if (month && toYear === year) {
                  if (toMonth < month) {
                    return false;
                  }
                }
              }
            }
          }

          if (model === "payments") {
            let date = d.date;
            if (date) {
              let [paymentYear, paymentMonth] = date.split("-");
              if (year) {
                if (paymentYear !== year) {
                  return false;
                }
              }
              if (month) {
                if (paymentMonth !== month) {
                  return false;
                }
              }
            }
          }

          if (model === "issued_invoices") {
            // let customer_id = d.tenant_id;
            let customer_id = d.client_id;
            if (customersF.length > 0 && !customersF.includes(customer_id)) {
              return false;
            }
          }

            const isCompanyFieldEmpty =
              "company_id" in d &&
              (d.company_id === null || d.company_id === "");
            const isCompanyIdsFieldEmpty =
              "company_ids" in d &&
              (d.company_ids === null || d.company_ids === "");
            const isProjectFieldEmpty =
              "project_id" in d &&
              (d.project_id === null || d.project_id === "");
            const isProjectIdsFieldEmpty =
              "project_ids" in d &&
              (d.project_ids === null || d.project_ids === "");
            const isSubProjectFieldEmpty =
              "sub_project_id" in d &&
              (d.sub_project_id === null || d.sub_project_id === "");
            const isSubProjectIdsFieldEmpty =
              "sub_project_ids" in d &&
              (d.sub_project_ids === null || d.sub_project_ids === "");
            const isBuildingFieldEmpty =
              "building_id" in d &&
              (d.building_id === null || d.building_id === "");

            if (
              (companyF && isCompanyFieldEmpty) ||
              (companyF && isCompanyIdsFieldEmpty) ||
              (projectF && isProjectFieldEmpty) ||
              (projectF && isProjectIdsFieldEmpty) ||
              (subProjectF && isSubProjectFieldEmpty) ||
              (subProjectF && isSubProjectIdsFieldEmpty) ||
              (buildingF && isBuildingFieldEmpty)
            ) {
              return false;
            }

          if (("company_id" in d || company_id) && companyF) {
            if (!companyF.includes(company_id)) return false;
          }

          if (("company_ids" in d || company_ids) && companyF) {
            if (!company_ids.some((r) => companyF.includes(r))) return false;
          }

          if (("project_id" in d || project_id) && projectF) {
            if (!projectF.includes(project_id)) return false;
          }

          if (("project_ids" in d || project_ids) && projectF) {
            if (!project_ids.some((r) => projectF.includes(r))) return false;
          }
          if (("sub_project_id" in d || sub_project_id) && subProjectF) {
            if (!subProjectF.includes(sub_project_id)) return false;
          }

          if (("sub_project_ids" in d || sub_project_ids) && subProjectF) {
            if (!sub_project_ids.some((r) => subProjectF.includes(r)))
              return false;
          }

          if (("building_id" in d || building_id) && buildingF) {
            if (!buildingF.includes(building_id)) return false;
          }

          return true;
        };
    }

    if (categoryF?.length > 0) {
      data = data.filter(
        (d) => !("kategorie" in d) || categoryF.includes(d?.kategorie)
      );
    }
    if (projectsF?.length > 0) {
      if (model === "projects") {
        data = data.filter((i) => projectsF.includes(i.id));
      } else {
        data = data.filter((d) => {
          let projects = getLocalJson("projects").filter((i) => {
            if (!i.company_ids) return false;
            let ids = i.company_ids.split("//");
            return ids.includes(d.company_id);
          });

          if (projects.length === 0) return true;

          return !("project_id" in d) || projectsF.includes(d?.project_id);
        });
      }
    }
    if (suppliersF?.length > 0) {
      if (model === "suppliers") {
        data = data.filter((i) => suppliersF.includes(i.id));
      } else {
        data = data.filter(
          (d) => !("supplier_id" in d) || suppliersF.includes(d?.supplier_id)
        );
      }
    }
    return data?.filter(predicate) ?? [];
  };

  const byAccessAndFilter = (data, model) => {
    if (!data) return [];
    let first = data[0];
    if (first) {
      if (
        !first.hasOwnProperty("company_id") &&
        !first.hasOwnProperty("agency_id") &&
        !first.hasOwnProperty("building_id") &&
        !first.hasOwnProperty("facility_id") &&
        !first.hasOwnProperty("account_number") &&
        !first.hasOwnProperty("account_number_id") &&
        !first.hasOwnProperty("account_id") &&
        !first.hasOwnProperty("to_account_number") &&
        !first.hasOwnProperty("company_ids") &&
        !first.hasOwnProperty("project_id") &&
        !first.hasOwnProperty("project_ids") &&
        !first.hasOwnProperty("sub_project_id") &&
        !first.hasOwnProperty("sub_project_ids") &&
        model !== "buildings" &&
        model !== "companies" &&
        model !== "projects" &&
        model !== "payments"
      ) {
        return data;
      }
    }

    let companyF = companyFilter ? [...companyFilter, null] : null;
    let projectF = projectFilter ? [...projectFilter, null] : null;
    let subProjectF = subProjectFilter ? [...subProjectFilter, null] : null;
    let buildingF = buildingFilter ? [...buildingFilter, null] : null;

    let filteredByAccess = filterData(
      data,
      model,
      allowedCompanies,
      allowedBuildings,
      allowedProjects,
      allowedSubProjects
    );
    let filtered = filterData(
      filteredByAccess,
      model,
      companyF,
      buildingF,
      projectF,
      subProjectF
    );
    return filtered;
  };

  const byAccess = (data, model) => {
    let first = data[0];
    if (!allowedCompanies && !allowedBuildings && !allowedProjects && !allowedSubProjects) return data;
    if (
      first &&
      model !== "companies" &&
      model !== "buildings" &&
      model !== "projects" &&
      model !== "finance_agencies"
    ) {
      if (
        !first.hasOwnProperty("project_id") &&
        !first.hasOwnProperty("project_ids") &&
        !first.hasOwnProperty("sub_project_id") &&
        !first.hasOwnProperty("sub_project_ids") &&
        !first.hasOwnProperty("company_id") &&
        !first.hasOwnProperty("agency_id") &&
        !first.hasOwnProperty("building_id") &&
        !first.hasOwnProperty("facility_id") &&
        !first.hasOwnProperty("company_ids")
      ) {
        return data;
      }
    }
    return filterData(
      data,
      model,
      allowedCompanies,
      allowedBuildings,
      allowedProjects,
      allowedSubProjects
    );
  };

  return (
    <FilterContext.Provider
      value={{
        setCompanyFilter: (c) => setCompanyFilter(c ? [c] : null),
        setBuildingFilter: (b) => setBuildingFilter(b ? [b] : null),
        setProjectFilter: (f) => setProjectFilter(f ? [f] : null),
        setSubProjectFilter: (f) => setSubProjectFilter(f ? [f] : null),
        setYearFilter: (y) => setYear(y),
        setMonthFilter: (m) => setMonth(m),
        getYearFilter: () => year,
        getMonthFilter: () => month,
        setPronajimatel,
        pronajimatel,
        byAccessAndFilter,
        byAccess,
        clear: () => setClearRequested(true),
        clearRequested,
        cleared: () => setClearRequested(false),
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
