import { useTranslation } from "react-i18next";
import LocaleCs from "../../aggrid/locale.cs";
import LocaleEn from "../../aggrid/locale.en";
import LocaleRu from "../../aggrid/locale.ru";
import useSettings from "../../hooks/useSettings";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import RichTextEditor from "../../aggrid/RichTextEditor.js";
import toast from "react-hot-toast";
import React, {
  useCallback,
  useEffect,
  useState,
  Children,
  cloneElement,
} from "react";
import HttpService from "../../services/HttpService";
import UrlService from "../../services/UrlService";
import { Helmet } from "react-helmet-async";
import { Delete } from "@material-ui/icons";
import {
  Autocomplete,
  TextField,
  Box,
  Button,
  IconButton,
  Container,
  Grid,
  LinearProgress,
  ThemeProvider,
  Typography,
  Popover,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SaveIcon from "@mui/icons-material/Save";
import { AgGridReact } from "ag-grid-react";
import { AllCommunityModules } from "ag-grid-community";
import addPropsToGrid from "../../utils/addPropsToGrid";
import getColumnDefinitions from "../../services/getColumnDefinitions";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import apiGet from "../../api/apiGet";
import apiPost from "../../api/apiPost";
import MultiFileModal from "./multiFileModal";
import Select from "../../aggrid/Select";
import FileEditor from "../../aggrid/FileEditor";
import CheckboxRenderer from "../../aggrid/CheckboxEditor";
import { OpenInNew } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteRenderer from "../../aggrid/DeleteRenderer";
import OptionsRenderer from "../../aggrid/OptionsRenderer";
import DownloadAttachment from "../../aggrid/downloadAttachment";
import DatePicker from "../../aggrid/DatePicker";
import DateTimePicker from "../../aggrid/DateTimePicker";
import useFilter from "../../hooks/useFilter";
import MultiSelect from "../../aggrid/MultiSelect";
import { createTheme } from "@material-ui/core/styles";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import {
  addToDataArray,
  updateDataInArray,
} from "../../utils/localStorageUtills";
import DateModal from "../dateModal/dateModal";
import JsxRenderer from "../../aggrid/JsxRenderer";
import ExternalLink from "../../icons/ExternalLink";
import Notes from "./notes";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import SimpleLoader from "../SimpleLoader";
import LinkToFotoRenderer from "../../utils/LinkToFotoRenderer.js";
import EmployeeCard from "../employee_card";
import Audit from "../../components/audit";

LicenseManager.setLicenseKey(
  "CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166"
);

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

const defaultFrameworkComponents = {
  Select: Select,
  FileEditor: FileEditor,
  CheckboxRenderer: CheckboxRenderer,
  OpenInNewRenderer: (params) => {
    return <OpenInNew sx={{ color: params.value ? "#4caf50" : "#5664d2" }} />;
  },
  DeleteRenderer: DeleteRenderer,
  OptionsRenderer: OptionsRenderer,
  DownloadAttachment: DownloadAttachment,
  DatePicker: DatePicker,
  DateTimePicker: DateTimePicker,
  MultiSelect: MultiSelect,
  JsxRenderer: JsxRenderer,
  LinkToFotoRenderer: LinkToFotoRenderer,
  RichTextEditor: RichTextEditor,
};

export default function GridWrapper({
  children,
  autoGroupColumnDef,
  withoutExcel,
  model,
  data,
  colDefs,
  helmet,
  title,
  frameworkComponents,
  gridProps,
  pinned,
  clickable,
  style,
  handleRowDataOnInit,
  interceptChange,
  interceptDelete,
  onUpdateResponse,
  hidden,
  defaultSort,
  defaultSortOrder,
  small,
  filter: handleRowData,
  getRowStyle,
  handleColumnDefs,
  onSelected,
  historyYear,
  historyMonth,
  needTemplate,
  forApproval,
  month: propsMonth,
  year: propsYear,
  uzivatel,
  rowSelection,
  interceptClick,
  needResize = true,
}) {
  const { t } = useTranslation();
  const filter = useFilter();
  let lt;
  if (t("cs") === "cs") lt = LocaleCs;
  else if (t("cs") === "en") lt = LocaleEn;
  else if (t("cs") === "ru") lt = LocaleRu;

  const policy_action_tree = JSON.parse(
    localStorage.getItem("policy_action_tree")
  );

  const { settings } = useSettings();
  const [rowData, setRowData] = useState(data ?? []);
  const [rowDataFilter, setRowDataFilter] = useState(() => (i) => {
    return true;
  });
  const [filteredData, setFilteredData] = useState([]);

  const [locText, setLocText] = useState(lt);

  const [selectedRows, setSelectedRows] = useState([]);

  const { data: contextData, isLoading } = useContext(DataContext);

  useEffect(() => {
    if (onSelected) {
      onSelected(selectedRows);
    }
  }, [onSelected, selectedRows]);

  useEffect(() => {
    setLocText(lt);
  }, [lt]);

  const [ready, setReady] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [columnDefs, setColumnDefs] = useState(colDefs ?? []);

  const [multiFileData, setMultiFileData] = useState(null);

  // const [year, setYear] = useState(null);
  // const [month, setMonth] = useState(null);
  const [openEployee, setOpenEmployee] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);
  const [noteId, setNoteId] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [employeeModel, setEmployeeModel] = useState("profile");
  const [showAudit, setShowAudit] = useState(false);
  const [showAuditId, setShowAuditId] = useState(0);

  const handleCloseEployee = () => {
    setOpenEmployee(false);
  };
  const handleOpenEmployee = () => {
    setOpenEmployee(true);
  };

  let customer = uzivatel ? `${uzivatel}` : "all";

  // useEffect(() => {
  //   let filterYear = filter.getYearFilter();
  //   let monthFilter = filter.getMonthFilter();

  //   if (!historyYear || !historyMonth) {
  //     if (filterYear !== year) {
  //       setYear(filterYear);
  //     }
  //     if (monthFilter !== month) {
  //       setMonth(monthFilter);
  //     }
  //   }
  // }, [filter]);

  // useEffect(() => {
  //   if (historyMonth && historyYear) {
  //     setYear(historyYear);
  //     setMonth(historyMonth);
  //   } else {
  //     setYear(null);
  //     setMonth(null);
  //   }
  // }, [historyMonth, historyYear]);

  const defaultColDef = {
    flex: 1,
    minWidth: 130,
    editable: false,
    resizable: true,
    suppressMovableColumns: true,
    sortable: true,
    filter: "agTextColumnFilter",
    menuTabs: ["filterMenuTab", "columnsMenuTab"],
    cellStyle: (params) => {
      if (
        params.colDef.field == "surname" &&
        model != "clients" &&
        !params.node.group
      ) {
        return { color: "#5664d2" };
      }
    },
  };

  useEffect(() => {
    let mounted = true;

    if (!colDefs) {
      if (columnDefs.length === 0 && isLoading === false) {
        getColumnDefinitions(t, model, contextData, false).then((d) => {
          if (mounted) {
            if (!d || d.length === 0) {
              alert("Žádné kolonky v modelu " + model);
              d = [];
            }
            if (pinned) {
              d.find((e) => e.field === pinned).pinned = "left";
            }
            if (hidden) {
              d = d.filter((def) => !hidden.includes(def.field));
            }
            if (policy_action_tree[model].includes(`delete`)) {
              d.push({
                headerName: t("Odstranit"),
                cellRendererSelector: function (params) {
                  if (params.node.group || params.data.deleted) {
                    return;
                  } else {
                    return {
                      component: "DeleteRenderer",
                      params: {
                        interceptDelete: interceptDelete,
                        model: model,
                        gridType: model,
                        setRowData,
                      },
                    };
                  }
                },
                suppressMenu: true,
                editable: false,
                resizable: false,
                sortable: false,
                minWidth: 100,
                maxWidth: 100,
              });
            }
            if (model === "supplier_invoices") {
              d.unshift({
                headerName: t("Čísla dodavatelských účtů"),
                field: "supplier_account_numbers",
                suppressMenu: true,
                editable: false,
                resizable: false,
                sortable: false,
                minWidth: 100,
                maxWidth: 100,
                cellRendererFramework: () => (
                  <Button>
                    <VisibilityIcon />
                  </Button>
                ),
              });
            }
            d.push({
              headerName: "#",
              valueGetter: "node.rowIndex + 1",
              lockPosition: "left",
              pinned: "left",
              maxWidth: 150,
              sortable: false,
              resizable: false,
              suppressMenu: true,
              checkboxSelection: true,
              headerCheckboxSelection:
                rowSelection && rowSelection === "single" ? false : true,
              headerCheckboxSelectionFilteredOnly: true,
            });

            if (
              model !== "ubyports" &&
              model !== "accommodations" &&
              model !== "coordinators_expences" &&
              model !== "coordinator_money_requests" &&
              model !== "medical_checkups" &&
              model !== "customers" &&
              model !== "customer_interactions" &&
              model !== "downpayments"
            ) {
              d.push({
                minWidth: 100,
                headerName: t("Poznámky"),
                field: "note",
                pinned: "left",
                cellRenderer: "JsxRenderer",
                cellRendererParams: {
                  jsx: (props) => (
                    <>
                      <Button
                        sx={{ ml: "-10px", width: "64px" }}
                        color="primary"
                        id="fil_attach"
                        className="btn btn-default btn-sm delete-ingrid-but"
                        fullWidth
                        onClick={() => {
                          setNoteId(props.data.id);
                          setNoteOpen(true);
                        }}
                      >
                        <ExternalLink />
                      </Button>
                      {props.value}
                    </>
                  ),
                },
              });
            }
            if (model === "received_invoices" && forApproval) {
              d.unshift(
                {
                  headerName: t("Příjem"),
                  field: "incomes",
                  suppressMenu: true,
                  editable: false,
                  resizable: true,
                  sortable: false,
                  minWidth: 100,
                },
                {
                  headerName: t("Výdaje"),
                  field: "expenses",
                  suppressMenu: true,
                  editable: false,
                  resizable: true,
                  sortable: false,
                  minWidth: 100,
                }
              );
            }
            for (const columnDef of d) {
              // console.log(model, columnDef);
              if (
                model == "received_invoices" &&
                columnDef.field == "approval_date"
              ) {
                columnDef.editable = false;
              }
              if (model == "downpayments" && columnDef.field == "card_id") {
                columnDef.maxWidth = 120;
              }

              if (
                model == "supplier_account_numbers" &&
                columnDef.field == "supplier_id"
              ) {
                columnDef.editable = false;
              } else if (
                model == "received_invoices" &&
                (columnDef.field == "supplier_id" ||
                  columnDef.field == "supplier_account_number_id")
              ) {
                columnDef.editable = false;
              }

              let sort = defaultSort ?? "updated_at";
              if (columnDef.field === sort) {
                columnDef.sort = defaultSortOrder ?? "desc";
              }
            }
            if (handleColumnDefs) {
              setColumnDefs(handleColumnDefs(d.filter((i) => i != false)));
            } else {
              setColumnDefs(d.filter((i) => i != false));
            }
          }
        });
      }
    }

    if (data === undefined) {
      // if (year && month) {
      //   date = `${year}/${month}`;
      // }
      if (forApproval) {
        HttpService.get(
          UrlService.apiDomain() + `api/for_approval_invoices`
        ).then((response) => {
          if (mounted) {
            let data = response?.data ?? [];
            setRowData(handleRowDataOnInit ? handleRowDataOnInit(data) : data);
            setReady(true);
          }
        });
        return function cleanup() {
          mounted = false;
        };
      }

      // !isLoading &&
      //   apiGet(`${model}/index/${date}/${customer}`).then((response) => {
      //     if (mounted) {
      //       let data = response?.data ?? [];
      //       setRowData(handleRowDataOnInit ? handleRowDataOnInit(data) : data);
      //       setReady(true);
      //     }
      //   });

      let filterYear = filter.getYearFilter();
      let monthFilter = filter.getMonthFilter();

      let date;

      if (propsMonth && propsYear) {
        date = `${propsYear}/${propsMonth}`;
      } else if (filterYear && monthFilter) {
        date = `${filterYear}/${monthFilter}`;
      } else date = "now/now";

      !isLoading && getData(model, date, customer, mounted);
    }

    return function cleanup() {
      mounted = false;
    };
  }, [
    model,
    handleRowDataOnInit,
    // year,
    // month,
    filter,
    isLoading,
    uzivatel,
    propsMonth,
    propsYear,
  ]);

  const getData = async (model, date, customer, mounted) => {
    try {
      const response = await apiGet(`${model}/index/${date}/${customer}`);
      if (mounted) {
        const data = response?.data ?? [];
        setRowData(handleRowDataOnInit ? handleRowDataOnInit(data) : data);
        setReady(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (data) {
      setRowData(handleRowDataOnInit ? handleRowDataOnInit(data) : data);
      setReady(true);
    }
  }, [data]);

  useEffect(() => {
    if (colDefs) {
      setColumnDefs(colDefs);
      setReady(true);
    }
  }, [colDefs]);

  const show_ondemand = (id, field) => {
    HttpService.get(
      UrlService.apiDomain() +
        "api/" +
        model +
        "/show_on_demand/" +
        id +
        "/" +
        field
    )
      .then((res) => {
        let node = gridApi.getRowNode(id - 1);
        node.data[field] = res.data.value;
        node.setData(node.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onCellClicked = (e) => {
    if (interceptClick) {
      interceptClick(e);
      return;
    }
    if (!e.node.group) {
      let field = e.colDef.field;
      if (e.data[field] === "ZOBRAZIT") {
        show_ondemand(e.data.id, field);
      }
      console.log(e);
      clickable?.find((i) => i.field === field)?.onClick(e.data, e);
      if (e.colDef.column_type === "multifile") {
        setMultiFileData({
          id: e.data.id,
          field: field,
          model: model,
          label: e.colDef.headerName,
        });
      }
      if (field == "surname" && model != "clients") {
        if (model == "medical_checkups") {
          setEmployeeModel("medical_checkups");
        }
        setEmployeeId(e.data.employee_id);
        handleOpenEmployee();
      }
    }
  };

  const [pendingChange, setPendingChange] = useState(null);

  const onCellValueChanged = (e) => {
    if (interceptChange) {
      interceptChange(e);
      return;
    }
    let field = e.colDef.field;
    let newValue = e.newValue;
    let id = e.data.id;

    if (newValue === true) {
      newValue = 1;
    } else if (newValue === false) {
      newValue = 0;
    }

    let data_array = [
      { key: "id", value: id },
      { key: "field", value: field },
      { key: "value", value: newValue },
    ];

    if (e.colDef.save_history !== "false" && e.colDef.save_history) {
      setPendingChange({ data: data_array, e: e });
      return;
    }

    postUpdate(data_array).then((newData) => {
      if (!newData) return;

      let row = gridApi?.getRowNode(e.node.id);
      if (!row) return;

      if (model == "downpayments") {
        let filterYear = filter.getYearFilter();
        let monthFilter = filter.getMonthFilter();
        let date;

        if (propsMonth && propsYear) {
          date = `${propsYear}/${propsMonth}`;
        } else if (filterYear && monthFilter) {
          date = `${filterYear}/${monthFilter}`;
        } else date = "now/now";

        getData(model, date, customer, true);
        return;
      }

      row.setData(newData);
    });
  };

  const postUpdate = (data_array, date) => {
    let url = `${model}/update`;
    if (date) {
      url += `/${date}`;
    }
    return apiPost(
      url,
      data_array,
      (response) => {
        console.log("Success: ", response.data);
        updateDataInArray(model, response.data);
        if (onUpdateResponse) {
          onUpdateResponse(response);
        }
        return response.data;
      },
      (error, response) => {
        alert("ERROR: " + error);
        if (response.data) {
          return response.data;
        }
        console.error("Response with ERROR: ", response);
      }
    );
  };

  const handleAdd = (item) => {
    let newData = rowData ? rowData.concat(item) : [item];
    setRowData(newData);
    if (model === "issued_invoices") {
      clickable[0]?.onClick();
    }
    addToDataArray(model, item);
  };

  const resizeAll = () => {
    if (gridColumnApi && needResize) {
      const allColumnIds = [];
      if (gridColumnApi.getAllColumns() !== undefined) {
        gridColumnApi.getAllColumns().forEach((column) => {
          allColumnIds.push(column.getId());
        });
        gridColumnApi.autoSizeColumns(allColumnIds, false);
      }
    }
  };

  useEffect(() => {
    if (!rowData) {
      setFilteredData(null);
      return;
    }
    let tempData = filter
      .byAccessAndFilter(rowData, model)
      .filter(rowDataFilter);
    if (handleRowData) {
      tempData = handleRowData(tempData);
    }
    setFilteredData(tempData);
  }, [handleRowData, rowData, filter, model, rowDataFilter]);

  // useEffect(() => {
  //   if (!rowData) {
  //     setFilteredData([]);
  //     return;
  //   }
  //   let tempData = rowData.filter(rowDataFilter);

  //   if (handleRowData) {
  //     tempData = handleRowData(tempData);
  //   }
  //   setFilteredData(tempData);
  // }, [handleRowData, rowData, rowDataFilter]);

  let body = addPropsToGrid(children, {
    onAdd: handleAdd,
    selected: selectedRows,
    setFilter: setRowDataFilter,
    rowData: rowData,
    getData: getData,
    setRowData: setRowData,
  });
  const [selectedTemplate, setSelectedTemplate] = useState(
    localStorage.getItem(`selected_template`) ?? "default"
  );

  useEffect(() => {
    localStorage.setItem(`selected_template`, selectedTemplate);
  }, [selectedTemplate]);

  const [columnState, setColumnState] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onFirstDataRendered = useCallback(
    (params) => {
      const columnFilter = JSON.parse(
        localStorage.getItem(`${model}_filter_state`)
      );
      if (columnFilter) {
        gridApi.setFilterModel(columnFilter);
      }
      if (columnState !== null && gridColumnApi) {
        gridColumnApi.setColumnState(columnState);
      }
    },
    [gridApi, gridColumnApi, columnState]
  );

  const onDragStopped = (params) => {
    console.log({ params });
    setColumnState(params.columnApi.getColumnState());
  };

  const onFilterChanged = (params) => {
    const columnFilter = JSON.stringify(params.api.getFilterModel());
    localStorage.setItem(`${model}_filter_state`, columnFilter);
  };

  const onColumnVisible = (params) => {
    setColumnState(params.columnApi.getColumnState());
  };
  const [template, setTemplate] = useState({});
  const [open, setOpen] = useState(false);
  const [templateName, setTemplateName] = useState([]);
  const [templateLable, setTemplateLable] = useState("");

  const lable = templateLable ? templateLable : "all";

  useEffect(() => {
    if (!needTemplate) return;
    HttpService.get(UrlService.apiDomain() + `api/get-templates`)
      .then((response) => {
        const loaded = response.data ?? [];
        loaded.push({
          label: t("Nový"),
          value: "default",
          data: null,
        });
        setTemplateName(loaded);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    HttpService.get(
      UrlService.apiDomain() +
        `api/templates/${model}/table/${selectedTemplate}/${lable}`
    )
      .then((response) => {
        setTemplate(response.data);
        if (response.data.data) {
          setColumnState(JSON.parse(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedTemplate]);

  useEffect(() => {
    if (columnState !== null && gridColumnApi) {
      gridColumnApi.setColumnState(columnState);
    }
  }, [columnState, gridColumnApi]);

  function addRemoveButtonToEndAdornment(endAdornment) {
    const children = [
      ...Children.toArray(endAdornment.props.children),
      <IconButton
        key={"delete"}
        sx={{ p: 0 }}
        onClick={() => {
          handleClickOpen();
        }}
      >
        <Delete />
      </IconButton>,
    ];

    return cloneElement(endAdornment, {}, children);
  }

  function addSaveButtonToEndAdornment(endAdornment) {
    const children = [
      ...Children.toArray(endAdornment.props.children),
      <IconButton
        key={"save"}
        sx={{ p: 0 }}
        onClick={() => {
          saveTemplate({
            value: selectedTemplate,
            label: templateLable,
            data: columnState,
          });
        }}
      >
        <SaveIcon />
      </IconButton>,
    ];

    return cloneElement(endAdornment, {}, children);
  }

  const saveTemplate = async (data) => {
    await HttpService.post(
      UrlService.apiDomain() + `api/templates/${model}/table`,
      data
    )
      .then(toast.success("Šablona byla úspěšně uložena"))
      .catch((error) => {
        toast.error(error);
        console.log(error);
      });
  };

  const deleteTemplate = async () => {
    try {
      const result = await HttpService.delete(
        UrlService.apiDomain() + `api/templates/${selectedTemplate}`
      );
      setTemplateName(
        templateName.filter((template) => template.value !== selectedTemplate)
      );
      setSelectedTemplate("default");
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (frameworkComponents) {
    frameworkComponents = {
      ...frameworkComponents,
      defaultFrameworkComponents,
    };
  } else {
    frameworkComponents = defaultFrameworkComponents;
  }

  const getContextMenuItems = (params) => {
    const result = ["copy", "paste", "export"];
    if (policy_action_tree.employees.includes("audit")) {
      result.push("separator");
      result.push({
        name: "Audit",
        checked: true,
        action: function () {
          setShowAudit(true);
          setShowAuditId(params.node.data.id);
        },
        icon: '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
      });
    }
    return result;
  };

  let table = (
    <div
      id="myGrid"
      className="ag-theme-material"
      style={
        small
          ? { width: "100%", height: "35vh", minHeight: "100%" }
          : {
              width: "100%",
              height: "50vh",
              minHeight: "100%",
            }
      }
    >
      <ThemeProvider theme={theme}>
        <AgGridReact
          style={{ width: 500, height: 200 }}
          onFirstDataRendered={onFirstDataRendered}
          autoGroupColumnDef={autoGroupColumnDef}
          modules={AllCommunityModules}
          onFilterChanged={onFilterChanged}
          rowData={filteredData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          onDragStopped={onDragStopped}
          onColumnVisible={onColumnVisible}
          onCellValueChanged={onCellValueChanged}
          getContextMenuItems={getContextMenuItems}
          onCellClicked={onCellClicked}
          onSelectionChanged={() => setSelectedRows(gridApi.getSelectedRows())}
          onSortChanged={(e) => {
            e.api.refreshCells();
          }}
          // onGridColumnsChanged={() => {
          //   resizeAll();
          // }}
          onRowDataChanged={() => {
            resizeAll();
          }}
          getRowStyle={getRowStyle}
          enableCellChangeFlash={true}
          rowSelection={rowSelection ? rowSelection : "multiple"}
          // suppressContextMenu={true}
          frameworkComponents={frameworkComponents}
          localeText={locText}
          popupParent={document.querySelector("body")}
          {...gridProps}
        />
      </ThemeProvider>
    </div>
  );
  if (isLoading) {
    return <SimpleLoader />;
  }

  if (small) {
    return table;
  }

  return (
    <>
      <Dialog
        maxWidth="lg"
        fullWidth={true}
        onClose={handleCloseEployee}
        open={openEployee}
      >
        <EmployeeCard
          id={employeeId}
          child_model={employeeModel}
          type="employees"
          onClose={handleCloseEployee}
        />
      </Dialog>

      {policy_action_tree.employees.includes("audit") && (
        <Popover
          anchorOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          anchorEl={document.querySelector("body")}
          keepMounted
          onClose={() => {
            setShowAudit(false);
          }}
          open={showAudit}
          PaperProps={{
            sx: { width: "1250px" },
          }}
        >
          <Audit type={model} id={showAuditId} />
        </Popover>
      )}
      <MultiFileModal
        data={multiFileData}
        onClose={() => setMultiFileData(null)}
        isOpen={multiFileData !== null}
      />
      <DateModal
        isOpen={!!pendingChange}
        onClose={() => setPendingChange(null)}
        onSubmit={(date) => {
          postUpdate(pendingChange.data, date).then((newData) => {
            if (!newData) return;

            let row = gridApi?.getRowNode(pendingChange.e.node.id);
            if (!row) return;

            row.setData(newData);
          });
          setPendingChange(null);
        }}
      />
      <Notes
        open={noteOpen}
        id={noteId}
        model={model}
        onClose={() => {
          setNoteOpen(false);
        }}
      />
      <Helmet>
        <title>{helmet}</title>
      </Helmet>
      <Box
        sx={
          style ?? {
            backgroundColor: "background.default",
            height: "100%",
            py: 4,
          }
        }
      >
        <Container
          // maxWidth={settings.compact ? "xl" : false}
          style={{ maxWidth: "100%", height: "100%" }}
        >
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={3}>
              <Typography color="textPrimary" variant="h5">
                {t(title)}
              </Typography>
            </Grid>
            <Grid item>
              <Box sx={{ mr: 2, display: "flex" }}>
                {needTemplate && (
                  <Box sx={{ mt: 1 }}>
                    <Autocomplete
                      size={"small"}
                      sx={{ width: "200px", mx: 1 }}
                      value={
                        templateName.find(
                          (t) => t.value === selectedTemplate
                        ) ?? ""
                      }
                      disableClearable
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Šablóna")}
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment:
                              selectedTemplate !== "default"
                                ? addRemoveButtonToEndAdornment(
                                    addSaveButtonToEndAdornment(
                                      params.InputProps.endAdornment
                                    )
                                  )
                                : params.InputProps.endAdornment,
                          }}
                        />
                      )}
                      options={templateName ?? []}
                      onChange={(e, newVal) => {
                        if (typeof newVal === "string") {
                          let value = newVal
                            .toLowerCase()
                            .trim()
                            .replace(" ", "_");
                          console.log("Value", value);
                          if (
                            newVal &&
                            !templateName.find(
                              (template) => template.value === value
                            )
                          ) {
                            setTemplate({
                              value: value,
                              label: newVal,
                              data: columnState,
                            });
                            setTemplateName([
                              ...templateName,
                              {
                                value: value,
                                label: newVal,
                              },
                            ]);
                            saveTemplate({
                              value: value,
                              label: newVal,
                              data: columnState,
                            });
                          }
                          setSelectedTemplate(value);
                        } else {
                          setSelectedTemplate(newVal.value);
                          setTemplateLable(newVal.label);
                        }
                      }}
                    />
                  </Box>
                )}
                {ready && body}
                {ready && !withoutExcel && (
                  <>
                    <Button
                      sx={{ ml: 4, px: 2 }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        gridApi.exportDataAsExcel({
                          processCellCallback: (params) => {
                            console.log(params.value, params);

                            if (
                              params.value &&
                              typeof params.value == "string" &&
                              /^\-?[0-9]+\,[0-9]{3}\.[0-9]{2}$/.test(
                                params.value
                              ) == true
                            ) {
                              params.value = params.value.substring(
                                0,
                                params.value.length - 3
                              );

                              params.value = params.value.replace(",", "");
                            } else if (
                              params.value &&
                              typeof params.value == "string" &&
                              params.value == "-"
                            ) {
                              params.value = "0";
                            }

                            const colDef = params.column.getColDef();

                            if (colDef.valueFormatter) {
                              const valueFormatterParams = {
                                ...params,
                                data: params.node.data,
                                node: params.node,
                                colDef: params.column.getColDef(),
                              };

                              return colDef.valueFormatter(
                                valueFormatterParams
                              );
                            }

                            return params.value;
                          },
                        });
                      }}
                    >
                      Excel
                    </Button>
                    <Button
                      sx={{ ml: 2, px: 2 }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        gridApi.setFilterModel(null);
                        gridApi.onFilterChanged();
                        filter.clear();
                      }}
                    >
                      <FilterAltOffIcon />
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{ mt: 3 }}
            style={{ width: "100%", height: "calc(100% - 50px)" }}
          >
            {!ready && <LinearProgress />}
            {table}
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("Potvrdit odstranění")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t(
                  "Jste si jisti, že chcete odstranit tuto šablóna a všechny šablony s ní spojené?"
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {t("Ne")}
              </Button>
              <Button onClick={deleteTemplate} color="primary" autoFocus>
                {t("Ano")}
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Box>
    </>
  );
}
