import React, { useState, forwardRef, useImperativeHandle } from "react";

import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import UrlService from "../services/UrlService";
import HttpService from "../services/HttpService";
import { useTranslation } from "react-i18next";

export default forwardRef((props, ref) => {
  const { t } = useTranslation();
  //const inputRef = useRef();
  const [value, setValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionsArray, setOptionsArray] = useState([]);

  //var optionsArray = [];
  console.log(props);
  var locale = "cs";
  if (localStorage.getItem("locale") !== null)
    locale = localStorage.getItem("locale");

  function countriesByContinents(continents) {
    return new Promise((resolve) => {
      HttpService.post(UrlService.apiDomain() + "api/countriesByContinents", {
        opts: continents,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  function statesByCountries(countries) {
    return new Promise((resolve) => {
      HttpService.post(UrlService.apiDomain() + "api/statesByCountries", {
        opts: countries,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  async function getOptions() {
    var table = "";
    var col = "";
    var column = "";

    if (props.gridType === "employees") {
      // Multi selects for companies table
      table = "employees";
      col = props.column.colId;
      if (
        typeof props.data.original_field !== "undefined" &&
        props.data.original_field !== ""
      )
        col = props.data.original_field;
      column = "";
      JSON.parse(localStorage.getItem("columns")).map((e) => {
        if (e.field === col && e.model === table) column = e;
        return true;
      });

      var values = [];
      if (column.data_array === "custom") {
        if (column.data !== "" && column.data !== null) {
          values = column.data;
        }
      } else if (
        column.data_array !== "" &&
        column.data_array !== null &&
        column.data_array !== "none"
      ) {
        values = JSON.parse(localStorage.getItem(column.data_array));
      }

      // (checking if user has options allowed)
      // var allowed_options = [];
      // JSON.parse(localStorage.getItem('my_users_meta')).map((e) => {
      //     if (e.name === table + '/columns-' + col) {
      //         allowed_options = e.value.split('//');
      //     }
      //     return true;
      // });
      let allowed_options = [];
      if (
        col == "agency" ||
        col == "agentura" ||
        col == "agency_id" ||
        col == "agencies"
      ) {
        allowed_options = JSON.parse(
          localStorage.getItem("policy_record_tree")
        ).agencies;
      } else if (
        col == "customer" ||
        col == "customer_id" ||
        col == "uzivatel" ||
        col == "customers"
      ) {
        allowed_options = JSON.parse(
          localStorage.getItem("policy_record_tree")
        ).customers;
      } else if (col == "ubytovna" || col == "hostel" || col == "hostel_id") {
        allowed_options = JSON.parse(
          localStorage.getItem("policy_record_tree")
        ).agency_accommodations;
      }

      values.map((e) => {
        if (
          allowed_options.includes(e.id.toString()) ||
          allowed_options.length === 0 ||
          (allowed_options.length === 1 && allowed_options[0] === "")
        ) {
          optionsArray.push({ value: e.id, label: t(e["name"]) });
        }
        return true;
      });
    } else {
      // All other multiseletc (roles, users, etc.)

      if (props.column.colId.indexOf("/") !== -1) {
        // permissions for exact data in columns
        table = props.column.colId.split("/")[0];
        col = props.column.colId.split("-")[1];
        column = "";
        JSON.parse(localStorage.getItem("columns")).map((e) => {
          if (e.field === col && e.model === table) column = e;
          return true;
        });

        if (column.column_type === "boolean") {
          // Yes-no column

          JSON.parse(localStorage.getItem("booleans")).map((e) => {
            optionsArray.push({ value: e.id, label: e["name_" + locale] });
            return true;
          });

          /* TEST
                     for(var i=1;i<50000; i++) {
                     optionsArray.push({value: i, label: i});
                     }
                     */
        } else {
          // checking data array
          if (
            column.data_array === "" ||
            column.data_array === "none" ||
            column.data_array === null
          ) {
            // any text column
            JSON.parse(localStorage.getItem("columns_permissions"))[table][
              col
            ].map((e) => {
              optionsArray.push({ value: e, label: e });
              return true;
            });
          } else {
            if (column.data_array === "custom") {
              // custom options column
              if (column.data !== null && column.data !== "") {
                column.data.map((e) => {
                  optionsArray.push({ value: e.id, label: e["name"] });
                  return true;
                });
              }
            } else {
              JSON.parse(localStorage.getItem(column.data_array)).map((e) => {
                optionsArray.push({ value: e.id, label: e["name"] });
                return true;
              });
            }
          }
        }
      } else if (props.column.colId.indexOf("-") !== -1) {
        // visible/editable (or documents) permissions
        table = props.column.colId.split("-")[0];
        if (props.column.colId.split("-")[1] == "documents") {
          JSON.parse(localStorage.getItem("documents")).map((e) => {
            if (e.active)
              optionsArray.push({ value: e["name"], label: e["name"] });
            return true;
          });
        } else {
          JSON.parse(localStorage.getItem("columns")).map((e) => {
            if (e.model === table)
              optionsArray.push({ value: e.field, label: e["name"] });
            return true;
          });
        }
      } else {
        if (props.column.colId === "country") {
          JSON.parse(localStorage.getItem("countries")).map((e) => {
            optionsArray.push({ value: e.id, label: e["name_" + locale] });
            return true;
          });
        } else if (props.column.colId === "users") {
          JSON.parse(localStorage.getItem("users")).map((e) => {
            optionsArray.push({ value: e.id, label: e["name"] });
            return true;
          });
        } else if (props.column.colId === "uzivatel") {
          JSON.parse(localStorage.getItem("customers")).map((e) => {
            optionsArray.push({ value: e.id, label: e["name"] });
            return true;
          });
        } else if (props.column.colId === "tags") {
          JSON.parse(localStorage.getItem("document_tags")).map((e) => {
            optionsArray.push({ value: e.id, label: e["name"] });
            return true;
          });
        } else {
          JSON.parse(localStorage.getItem(props.column.colId + "s")).map(
            (e) => {
              optionsArray.push({ value: e.id, label: e.name });
              return true;
            }
          );
        }
      }
    }

    var clean_options = [];
    optionsArray.map((e) => {
      if (e.label !== "" && e.label !== null) clean_options.push(e);
      return true;
    });

    setOptionsArray(clean_options);

    try {
      optionsArray.sort((a, b) => a.label.localeCompare(b.label));
    } catch (err) {
      console.log(err);
    }

    setOptionsArray(optionsArray);
  }

  var all = "All";
  var selectedtext = "selected";
  if (locale === "cs") all = "Vše";
  if (locale === "cs") selectedtext = "vybráno";

  var placeholder = "Select";
  if (locale === "cs") placeholder = "Vyberte";

  function onChange(value, event) {
    //console.log(value);
    var finaloptions = [];
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
      finaloptions = this.options;
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
      finaloptions = [];
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
      finaloptions = value.filter((o) => o.value !== "*");
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
      finaloptions = this.options;
    } else {
      this.setState(value);
      finaloptions = value;
    }
    var val = "";
    finaloptions.map((i) => {
      //console.log(i);
      if (i.value !== "*") val += i.value + "//";
      return true;
    });
    props.colDef.field == "role" ||
    props.colDef.field == "allowed_reward_rule_ids"
      ? setValue(finaloptions.map((i) => i.value.toString()))
      : setValue(val.slice(0, -2));
  }

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: ` + all;
    } else {
      return `${placeholderButtonLabel}: ${value.length} ` + selectedtext;
    }
  }

  function getVal(e) {
    console.log("sssd" + props.value);
    if (
      props.value !== "" &&
      props.value !== null &&
      typeof props.value != "undefined"
    ) {
      var xselected = [];
      var xselectedOptions = [];

      props.value.split("//").map((e) => xselected.push(e));
      optionsArray.map((e) => {
        xselected.map((i) => {
          if (i === e.label) xselectedOptions.push(e);
          return true;
        });
        return true;
      });
      setSelectedOptions(xselectedOptions);
      return xselectedOptions;
    }
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },
      afterGuiAttached: async () => {
        setValue(props.value);
        await getOptions();
        getVal();

        /*
                 inputRef.current.focus();
                 inputRef.current.select();
                 */
      },

      isPopup: () => {
        return true;
      },
    };
  });

  return (
    <ReactMultiSelectCheckboxes
      options={[{ label: all, value: "*" }, ...optionsArray]}
      placeholderButtonLabel={placeholder}
      getDropdownButtonLabel={getDropdownButtonLabel}
      value={selectedOptions}
      onChange={onChange}
      setState={setSelectedOptions}
    />
  );
});
