import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Backspace, Clear } from "@material-ui/icons";
import { IconButton, TextField, ThemeProvider } from "@material-ui/core";
import { DesktopDatePicker, LocalizationProvider } from "@material-ui/lab";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { createTheme } from "@material-ui/core/styles";

dayjs.extend(customParseFormat);

const theme = createTheme({
    palette: {
        type: 'light',
        primary: { main: '#3f51b5' },
        secondary: { main: '#f50057' },
    },
});

const parseDate = (dateStr) => {
    if (!dateStr) return null;

    if (dayjs(dateStr, 'DD.MM.YYYY HH:mm:ss', true).isValid()) {
        return dayjs(dateStr, 'DD.MM.YYYY HH:mm:ss');
    }

    if (/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(dateStr)) {
        const [day, month, year] = dateStr.split('.');
        return dayjs(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`, 'YYYY-MM-DD');
    }

    if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateStr)) {
        return dayjs(dateStr, 'YYYY-MM-DD');
    }

    if (dayjs(dateStr, 'YYYY-MM-DD HH:mm:ss', true).isValid()) {
        return dayjs(dateStr, 'YYYY-MM-DD HH:mm:ss');
    }

    return null;
};

export default forwardRef((props, ref) => {
    const [value, setValue] = useState(parseDate(props?.value)); 
    const [valueStr, setValueStr] = useState('');

    useEffect(() => {
        const parsedValue = parseDate(props?.value);
        setValue(parsedValue); 
        setValueStr(parsedValue ? parsedValue.format('DD.MM.YYYY HH:mm:ss') : '');
    }, [props.value]);

    const [lastValid, setLastValid] = useState(value ? value.format('YYYY-MM-DD HH:mm:ss') : null);

    useImperativeHandle(ref, () => ({
        getValue: () => lastValid,
        isPopup: () => true,
    }));

    useEffect(() => {
        if (!valueStr) {
            setLastValid(null);
        } else if (dayjs(valueStr, 'DD.MM.YYYY HH:mm:ss', true).isValid()) {
            setLastValid(dayjs(valueStr, 'DD.MM.YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
        }
    }, [valueStr]);

    const handleClear = () => {
        setValue(null);
        setValueStr('');
    };

    const handleClose = () => {
        if (props.stopEditing) props.stopEditing();
    };

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    inputFormat="dd.MM.yyyy HH:mm:ss"
                    value={value?.toDate() || null}
                    onChange={(date) => {
                        if (!date) {
                            setValue(null);
                            setValueStr('');
                            return;
                        }
                        const dayjsDate = dayjs(date);
                        setValue(dayjsDate);
                        setValueStr(dayjsDate.format('DD.MM.YYYY HH:mm:ss'));
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            value={valueStr}
                            onChange={(e) => setValueStr(e.target.value)}
                        />
                    )}
                    InputProps={{
                        endAdornment: (
                            <>
                                <IconButton title="Clear" onClick={handleClear}>
                                    <Backspace />
                                </IconButton>
                                <IconButton title="Close" onClick={handleClose}>
                                    <Clear />
                                </IconButton>
                            </>
                        ),
                    }}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
});
